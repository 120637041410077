<template>
  <AnalisisComponent v-if="params !== null" :params="params"/>
</template>

<script>
import AnalisisComponent from "@/components/AnalisisComponent.vue";
import axios from "axios";
export default {
  components: { AnalisisComponent },
  data() {
    return {
      params:null,
    };
  },
  beforeMount() {
    axios
      .post(
        process.env.VUE_APP_API_URL+"/getParamsGraficos", 
        { id_dispositivo: localStorage.getItem("dispositivos")},
         
      )
      .then((res) => {
        this.params = res.data;
      })
      .catch((err) => console.error(err))
      .finally(() => {});
  },
};
</script>

<style>
</style>