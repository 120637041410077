<template>
  <v-app-bar color="rgb(57, 109, 98)" dark app clipped-left src="../assets/bg.png">
    <v-app-bar-nav-icon @click="changeMini" v-if="!$vuetify.breakpoint.mobile">
    </v-app-bar-nav-icon>
    <v-img src="../assets/logo.png" height="40" max-width="80"></v-img>
    <v-toolbar-title class="ml-5 d-none d-sm-block kozuka-b notranslate">Smart<span class="kozuka-l">Water</span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-col md="2" cols="6">
      <v-select v-if="multiFinca" :items="fincas" :value="fincaSelected" item-text="nombre" item-value="dispositivos"
        dense outlined flat class="mt-6 pb-0" @change="changeFinca($event)">
      </v-select>
      <v-container v-else class="d-flex justify-end">
        {{ fincas[0].nombre }}
      </v-container>
    </v-col>
    <v-menu v-model="menu" :nudge-width="200" offset-x>
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab text v-bind="attrs" v-on="on" :class="$vuetify.breakpoint.mobile ? 'mr-0' : 'mr-4'">
          <v-avatar color="teal" size="50" style="cursor: pointer">
            <img v-if="avatar_image" :src="avatar_image" alt="Imagen seleccionada" />
            <span v-else>{{ avatar }}</span>
          </v-avatar>
        </v-btn>
      </template>
      <!-- Contenido del menú -->
      <v-card @mouseleave="cerrarMenu">
        <div class="d-flex justify-end pa-2">
          <v-btn text fab x-small left @click="editar">
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex align-center justify-center">
          <div class="d-flex flex-column align-center">
            <v-avatar color="teal" size="85" style="cursor: pointer" @click="editar">
              <img v-if="avatar_image" :src="avatar_image" alt="Imagen seleccionada" />
              <span v-else style="color:white; font-size: 2rem;">{{ avatar }}</span>
            </v-avatar>
            <span class="mt-2">{{ name_user }}</span>
            <span class="text-caption">{{ email }}</span>
          </div>
        </div>
        <v-divider class="ma-3"></v-divider>
        <div class="d-flex justify-space-around pb-3">
          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-btn color="red darken-2" fab dark small style="cursor: pointer" @click="logout">
                  <v-icon dark>mdi-exit-to-app</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-subtitle>Cerrar sesión</v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </div>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
import axios from "axios";
export default {
  name: "AppBar",
  data() {
    return {
      status: false,
      battery: 10,
      hover: false,
      menu: false,
      avatar_image: null,
      name_user: '',
      lastname_user: '',
      email: '',
      fincas: JSON.parse(localStorage.getItem('fincas'))
    };
  },
  methods: {
    changeMini: function () {
      this.$emit("changeMini");
    },
    logout() {
      localStorage.clear();
      this.$router.push("/login");
    },
    editar() {
      // Verifica si la ruta actual es diferente de "/Usuario" para redireccionar
      if (this.$route.path !== "/Usuario") {
        this.$router.push("/Usuario");
        this.menu = false;
      } // Si la ruta actual es "/Usuario" vuelve a cargar la página
      else {
        this.$router.go();
      }
    },
    changeFinca(id_dispositivo) {
  
      this.$emit("changeFinca", id_dispositivo)
    },
    handleAvatarUpdated(url_avatar) {
      this.avatar_image = url_avatar;
    },
    handleNameUpdated(name) {
      this.name_user = name;
    },
    handleEmailUpdated(email) {
      this.email = email;
    },
    handleLastnameUpdated(lastname) {
      this.lastname_user = lastname;
    },
    cerrarMenu() {
      this.menu = false;
    },
    // Logica para obtener datos del usuario
    getUserInfo() {
      const id = localStorage.getItem("id");
      axios
        .post(process.env.VUE_APP_API_URL + "/getUserInfo", {
          id: id,
        })
        .then(res => {
          this.name_user = res.data.nombre;
          this.lastname_user = res.data.apellido;
          this.email = res.data.email;
          this.avatar_image = res.data.URL_avatar;
        })
        .catch((error) => {
          console.error('Error al obtener los datos del usuario:', error);
        });
    },
  },

  computed: {
    avatar() {
      const nombre = localStorage.getItem("nombre");
      const apellido = localStorage.getItem("apellido");
      return nombre[0] + apellido[0];
    },
    // Logica para obtener la finca seleccionada
    fincaSelected() {
      // Obtiene el valor de la finca seleccionada
      const dispositivos = localStorage.getItem("dispositivos");
      
      // Verifica si la finca seleccionada es diferente de null
      try {
        // Busca la finca seleccionada en el array de fincas con el valor de dispositivos como referencia y también su id
        const finca = this.fincas.find(finca => (finca.dispositivos).replace(" ", "") === dispositivos)
        // Retorna la finca seleccionada
        return finca
      }
      catch {
        // Si no encuentra la finca seleccionada, retorna la primera finca
        return this.fincas[0];
      }
    },
    multiFinca() {
      try {
        if (this.fincas.length > 1) {
          return true
        } else {
          return false;
        }
      }
      catch {
        return false;
      }
    }
  },
  mounted() {
    this.getUserInfo();
    this.$root.$on("update-avatar", this.handleAvatarUpdated);
    this.$root.$on("update-nombre", this.handleNameUpdated);
    this.$root.$on("update-apellido", this.handleLastnameUpdated);
    this.$root.$on("update-email", this.handleEmailUpdated);
  },
};
</script>

<style>
@import url(../styles/fonts.css);
</style>