<template>
    <div id="dashboard-map">
        <GmapMap v-if="isLoaded && contadores.length" :center="centerMap" :zoom="map_zoom" map-type-id="satellite"
            class="w-100" :style="$vuetify.breakpoint.lgAndDown ? 'height:510px;' : 'height:730px'"
            :options="{ disableDefaultUI: false }" @loaded="setZoomListener">
            <GmapMarker v-for="(m, index) in filteredMarkers" :key="index" :position="m.position" :clickable="true"
                :draggable="false" :label="m.label" :icon="m.icon" :type="m.type" :opacity="0.8" />
        </GmapMap>
        <div v-else class="no-contadores">
            <v-icon large color="red darken-2">mdi-information</v-icon>
            <span>No hay contadores para visualizar</span>
        </div>
    </div>
</template>

<script>
import contador_off from "@/assets/contador_off.png";
import contador_on from "@/assets/contador_on.png";

export default {
    props: ["contadores"],
    data() {
        let isLoaded = false;
        let contadoresAbiertos = [];

        if (this.contadores.length > 0 && this.contadores[0].latitud !== null) {
            isLoaded = true;
        }
        this.contadores.map((el) => {
            if (el.n_pulsos > 1) {
                contadoresAbiertos.push(el);
            }
        });
        return {
            centerMap: { lat: 0, lng: 0 },
            isLoaded,
            markers: [],
            contadoresAbiertos,
            show_menu: false,
            show_contadores: true,
            icon_color: 'grey darken-1',
            posiciones_contadores: [],
            map_zoom: 15,
        };
    },
    watch: {
        //Cada vez que se actualizan las contadores se actualizan las contadores en el mapa
        contadores: function (newVal) {
            let newcontadoresAbiertos = [];
            newVal.map((el) => {
                if (el.n_pulsos >= 1) {
                    newcontadoresAbiertos.push(el);
                }
            });
            //Comparo las contadores abiertas con las nuevas contadores abiertas
            if (
                JSON.stringify(newcontadoresAbiertos) !=
                JSON.stringify(this.contadoresAbiertos)
            ) {
                this.markers = [];
                newVal.map((el) => {
                    this.markers.push({
                        position: {
                            lat: parseFloat(el.latitud),
                            lng: parseFloat(el.longitud),
                        },
                        label: {
                            text: el.nombre,
                            color: "white",
                        },
                        icon: el.n_pulsos > 0 ? contador_on : contador_off,
                        data: el
                    });
                });
            }
            this.contadoresAbiertos = newcontadoresAbiertos;
        },
    },
    computed: {
        filteredMarkers() {
            if (this.show_contadores) {
                return this.markers;
            } else {
                return [];
            }
        },

    },
    /* global google */
    methods: {
        computeMapZoom() {
            // Si contadores está vacío return
            if (this.contadores.length === 0) {
                return;
            }

            let minLat = Infinity;
            let maxLat = -Infinity;
            let minLng = Infinity;
            let maxLng = -Infinity;

            // Encuentra las coordenadas extremas
            for (let i in this.contadores) {
                if (this.contadores[i].latitud != 0 && this.contadores[i].longitud != 0) {
                    minLat = Math.min(minLat, parseFloat(this.contadores[i].latitud));
                    maxLat = Math.max(maxLat, parseFloat(this.contadores[i].latitud));
                    minLng = Math.min(minLng, parseFloat(this.contadores[i].longitud));
                    maxLng = Math.max(maxLng, parseFloat(this.contadores[i].longitud));
                }
            }

            // Calcula la distancia máxima entre las coordenadas extremas en latitud y longitud
            const latDiff = maxLat - minLat;
            const lngDiff = maxLng - minLng;

            // Establecer una constante de ajuste basada en la distancia
            const maxDiff = Math.max(latDiff, lngDiff);
            const earthCircumference = 40075;
            const zoomFactor = Math.log2(earthCircumference / (maxDiff * 110.574));

            // Establece el zoom del mapa
            this.map_zoom = Math.round(zoomFactor - Math.log2(1));
        },
        computedCenterCoordinate() {
            let lat = 0;
            let lng = 0;
            let count = 0;

            // Calculo el centro de los contadores sin tener en cuenta los contadores que no tienen coordenadas o son 0,0

            for (let i in this.contadores) {
                if (this.contadores[i].latitud != 0 && this.contadores[i].longitud != 0) {
                    lat += parseFloat(this.contadores[i].latitud);
                    lng += parseFloat(this.contadores[i].longitud);
                    count++;
                }
            }
            this.centerMap = { lat: lat / count, lng: lng / count };
        },
        changeIconColor(color) {
            this.icon_color = color;
        },
        updateMarkers() {
            // Actualiza los marcadores según los checkboxes
            this.$nextTick(() => {
                if (this.$refs.gmap) {
                    this.googleMarkers = this.$refs.gmap.$mapObject.markers;
                    this.setZoomListener();
                }
            });
        },
        setZoomListener() {
            const gmapRef = this.$refs.gmap;

            if (gmapRef && gmapRef.$mapObject) {
                const map = gmapRef.$mapObject;

                // Event listener al mapa que se ejecuta cada vez que cambia el zoom
                google.maps.event.addListener(map, 'zoom_changed', () => {
                    // Calcula la escala del icono dependiendo del zoom
                    const zoomLevel = map.getZoom();
                    const baseIconSize = 28;
                    const zoomMultiplier = 2.8; // Controla la escala del icono

                    // Ajusta el zoomMultiplier para hacer los iconos más grandes a medida que aumenta el nivel de zoom
                    const adjustedZoomMultiplier = zoomMultiplier + (zoomLevel - 15) * 0.1;

                    // Calcula el nuevo tamaño del icono
                    const newIconSize = zoomLevel <= 15 ? baseIconSize : zoomLevel * adjustedZoomMultiplier;

                    // Recorre todos los marcadores y les cambia el tamaño
                    this.googleMarkers.forEach((marker) => {
                        const icon = {
                            url: marker.icon.url,
                            scaledSize: new google.maps.Size(newIconSize, newIconSize),
                        };

                        marker.setIcon(icon);
                    });
                });
            } else {
                console.error('Error: $refs.gmap or $refs.gmap.$mapObject is undefined.');
            }
        },
        //Función para obtener los marcadores de las contadores y  en el mapa
        getMarkers() {
            this.contadores.map((el) => {
                this.markers.push({
                    position: {
                        lat: parseFloat(el.latitud),
                        lng: parseFloat(el.longitud),
                    },
                    label: {
                        text: el.nombre,
                        color: "white",
                    },
                    icon: el.n_pulsos > 0 ? contador_on : contador_off,
                    data: el
                });
            });
        },

        //   processLocationOfContadores(){
        //     // Procesa las coordenadas de las contadores
        //     for ( let i in this.contadores){
        //         this.posiciones_contadores.push({
        //             lat: parseFloat(this.contadores[i].latitud),
        //             lng: parseFloat(this.contadores[i].longitud),
        //         });
        //     }

        //     // Poner los labels de los contadores como String 
        //     for (let i in this.contadores){
        //         this.contadores[i].nombre = this.contadores[i].nombre.toString();
        //     }
        //   }
    },
    beforeMount() {
        this.getMarkers();
        this.computedCenterCoordinate();
        this.computeMapZoom();
    },
    mounted() {
        this.$nextTick(() => {
            if (this.$refs.gmap) {
                // Almacena los marcadores en googleMarkers para referenciarlos más tarde
                this.googleMarkers = this.$refs.gmap.$mapObject.markers;
                this.setZoomListener();
            }
        });
    },
};
</script>

<style scoped>
.no-contadores {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-bottom: 20px;
    border: 2px dashed #009688;
    border-radius: 10px;
    padding: 20px;
    animation: fadeIn 1s ease-in-out;
}

.no-contadores v-icon {
    margin-bottom: 10px;
}

.no-contadores span {
    font-size: 16px;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
</style>
