<template>
  <div>
    <v-row v-if="!$vuetify.breakpoint.mobile">
      <v-col cols="12" sm="3">
        <CardInfo title="Previsión" :value="weatherInfo" :icon="weatherIcon" :iconColor="weatherColor" :location="weatherLocation" :humidity="weatherHumidity" :airQuality="weatherAirQuality"
          :forecasts="weatherForecasts" />
      </v-col>
      <v-col cols="12" sm="3">
        <CardInfo :titleClass="valvulasTitleClass" title="Válvulas abiertas" :value="formattedValvulasConectadas"
          icon="mdi-network" :iconColor="valvulasIconColor" :valves="connectedValves" />
      </v-col>
      <v-col cols="12" sm="3">
        <CardInfo :title="widgetDetails.title" :value="widgetDetails.value" :icon="widgetDetails.icon"
          :iconColor="widgetDetails.iconColor" />
      </v-col>
      <v-col cols="12" sm="3">
        <CardInfo title="ECOS Core" :value="is_connect" :image="core" />
      </v-col>
    </v-row>
    <v-row :dense="$vuetify.breakpoint.mobile">
      <v-col cols="12" sm="4" v-if="variadores != null && this.variadores.length > 0">
        <v-card v-if="variadores">
          <VariadoresDashboard :variadoresData="variadores" :valvula="valvula"
            :sensorSelectedOnMap="sensorSelectedOnMap" @getVariadores="getVariadores"
            @updateParametro="updateParametro" />
        </v-card>
      </v-col>
      <v-col cols="12" sm="8" v-if="sensores != null && variadores != null && this.variadores.length > 0">
        <v-card v-if="isSensoresLoaded && isValvulasLoaded && variadores">
          <RemoteDashboard :variadoresData="variadores" :remoteId="selectedRemoteId"
            :sensorSelectedOnMap="sensorSelectedOnMap" :key="key" :sensores="sensores" :valvula="valvula"
            :valvulas="electrovalvulas" @close-card="handleCloseCard" />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="5" v-if="sensores != null && variadores && this.variadores.length == 0">
        <v-card v-if="isSensoresLoaded && isValvulasLoaded && variadores">
          <RemoteDashboard :variadoresData="variadores" :remoteId="selectedRemoteId"
            :sensorSelectedOnMap="sensorSelectedOnMap" :sensores="sensores" :valvula="valvula"
            :valvulas="electrovalvulas" @close-card="handleCloseCard" />
        </v-card>
      </v-col>
      <v-col cols="12" sm="5" v-if="variadores != null && sensores == null">
        <v-card v-if="variadores">
          <VariadoresDashboard :variadoresData="variadores" :valvula="valvula"
            :sensorSelectedOnMap="sensorSelectedOnMap" @getVariadores="getVariadores"
            @updateParametro="updateParametro" />
        </v-card>
      </v-col>
      <v-col>
        <v-card :class="$vuetify.breakpoint.mobile ? 'mb-10' : ''">
          <v-card-text v-if="isSensoresLoaded" style="padding: 0px">
            <MapDashboard :valvulas="electrovalvulas" :sensores="sensores" @emitIdValve="selectedValve"
              @getElectrovalvulas="getElectrovalvulas" v-if="isSensoresLoaded && isValvulasLoaded" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndDown">
      <v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VariadoresDashboard from "@/components/VariadoresDashboard.vue";
import MapDashboard from "@/components/MapDashboard.vue";
import CardInfo from "@/components/CardInfo.vue";
import RemoteDashboard from "@/components/RemoteDashboard.vue";
import core from "@/assets/core-icon.png";
import axios from "axios";
import { format, addDays } from 'date-fns';
import { es } from 'date-fns/locale';

export default {
  components: {
    VariadoresDashboard,
    MapDashboard,
    CardInfo,
    RemoteDashboard,
  },
  data() {
    return {
      variadores: null,
      sensorId: null,
      sensorSelectedOnMap: null,
      isSensoresLoaded: false,
      isValvulasLoaded: false,
      electrovalvulas: [],
      sensores: [],
      valvula: -1,
      isAuto: -1,
      battery: 100,
      key: 0,
      core: core,
      markers: [],
      electrovalvulaId: null,
      selectedRemoteId: null,
      idInterval: null,
      valvulasConectadas: 0,
      ultimoDatoHumedad: null,
      weatherInfo: 'Cargando...',
      weatherIcon: 'mdi-weather-partly-cloudy',
      weatherColor: 'yellow darken-2',
      weatherHumidity: '-',
      weatherAirQuality: '-',
      weatherLocation: '',
      weatherForecasts: [],
      is_connect: '-',
    };
  },
  computed: {
    formattedValvulasConectadas() {
      return `${this.valvulasConectadas}`;
    },
    valvulasIconColor() {
      return this.valvulasConectadas === 0 ? 'blue-grey lighten-3' : 'teal darken-2';
    },
    valvulasTitleClass() {
      return this.valvulasConectadas === 0 ? 'blue-grey--text lighten-3' : '';
    },
    formattedUltimoDatoHumedad() {
      return this.ultimoDatoHumedad ? `${this.ultimoDatoHumedad.historico.values.slice(-1)[0].toFixed(0)}` : '-';
    },
    connectedValves() {
      return this.electrovalvulas.filter(valvula => valvula.current_state === 1).map(valvula => valvula.nombre);
    },
    widgetDetails() {
      const variadorConPresionCabezal = this.variadores?.find(variador =>
        variador.parametros.some(parametro => parametro.param === 'presion_cabezal')
      );

      if (variadorConPresionCabezal) {
        // Se obtiene el valor_actual, se verifica si es "error" o null, y se asigna '-' en esos casos
        let valorActual = variadorConPresionCabezal.parametros.find(parametro => parametro.param === 'presion_cabezal').valor_actual;
        const presionCabezal = (valorActual !== "error" && valorActual !== null) ? valorActual : '-';
        return {
          title: 'Presión Cabezal',
          value: `${presionCabezal}`,
          icon: 'mdi-gauge',
          iconColor: '#ff9800'
        };
      } else if (this.ultimoDatoHumedad) {
        return {
          title: 'Humedad',
          value: this.formattedUltimoDatoHumedad,
          icon: 'mdi-water-percent',
          iconColor: '#0277BD'
        };
      } else {
        return {
          title: 'Presión Cabezal',
          value: '-',
          icon: 'mdi-gauge',
          iconColor: '#ff9800'
        };
      }
    }
  },
  methods: {
    //Función que se ejecuta cuando se presiona o pincha el botón de cerrar 
    handleCloseCard() {
      this.valvula = -1;
      this.sensorSelectedOnMap = null;
    },
    //Funcion que se ejecuta cuando se selecciona algun item del mapa, sea sensor o valvula
    selectedValve(selection) {
      if (selection.type === "valvula") {
        this.electrovalvulaId = selection.data.id;
        this.selectedRemoteId = selection.data.id_remote;
        this.valvula = this.electrovalvulas.find(
          (valvula) => valvula.id === selection.data.id
        );

      } else if (selection.type === "sensor") {
        this.sensorId = selection.data.id;
        this.selectedRemoteId = selection.data.id_remote;
        this.sensorSelectedOnMap = this.sensores.find(
          (sensor) => sensor.id === selection.data.id
        );
        // this.readSensorAndSaveData(selection.data.id);
      }
    },
    readSensorAndSaveData(id) {
      //Si hay un sensor y una valvula seleccionada se ejecuta la funcion
      if (this.valvula.id_remote != null && (this.sensorId != null || id)) {
        this.alertWait = true;
        let idSelected = id ?? this.sensorId;
        let bodyFormData = new FormData();
        bodyFormData.append("function", "readSensorAndSaveData");
        bodyFormData.append("id_remote", this.valvula.id_remote);
        bodyFormData.append("id_sensor", idSelected);

        axios({
          url: process.env.VUE_APP_API_MQTT_URL,
          method: "POST",
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }
    },
    //Actualizo el valor del parametro indicado
    updateParametro(id_variador, id, valor, tipo_valor) {
      if (tipo_valor != 'off,on') {
        valor = valor.toFixed(1);
      }

      this.variadores
        .find((variador) => variador.id === id_variador)
        .parametros.find((parametro) => parametro.id === id).valor_actual =
        valor;
    },
    //Obtenemos los sensores
    getSensores() {
      axios
        .post(process.env.VUE_APP_API_URL + "/getSensores/1", {
          id_dispositivo: localStorage.getItem("dispositivos"),
        })
        .then((res) => {
          this.sensores = res.data;

          // Obtenemos el último dato de humedad
          const sensorHumedad = this.sensores.find((sensor) => sensor.id_tipo_sensor === 16);
          if (sensorHumedad && sensorHumedad.historico && sensorHumedad.historico.values.length) {
            this.ultimoDatoHumedad = sensorHumedad;
          }
        })
        .finally(() => {
          this.isSensoresLoaded = true;
        });
    },
    // Obtenemos las electrovalvulas
    getElectrovalvulas() {
      axios
        .post(process.env.VUE_APP_API_URL + "/getElectrovalvulas", {
          id_dispositivo: localStorage.getItem("dispositivos"),
        })
        .then((res) => {
          this.electrovalvulas = res.data;
          // Cuenta las válvulas conectadas
          this.valvulasConectadas = this.electrovalvulas.filter((valvula) => valvula.current_state === 1).length;

          // Obtener la primera válvula con coordenadas y obtener el clima
          const valvulaConCoordenadas = this.electrovalvulas.find((valvula) => valvula.latitud && valvula.longitud);

          if (valvulaConCoordenadas) {
            this.getWeather(valvulaConCoordenadas.latitud, valvulaConCoordenadas.longitud);
          } else {
            this.weatherInfo = '- °C';
          }
        })
        .finally(() => {
          this.isValvulasLoaded = true;
        });
    },
    getCoreData() {
      axios
        .post(process.env.VUE_APP_API_URL + "/mqtt/getCoreData", {
          id_dispositivo: localStorage.getItem("dispositivos"),
        })
        .then((res) => {
          // Si responde es que está conectado sino no
          this.is_connect = res.data ? 'Online' : 'Offline';

        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
          this.is_connect = 'Offline';
        });
    },
    // Deprecated
    // // Obtenemos los variadores
    getVariadores() {
      axios
        .post(process.env.VUE_APP_API_URL + "/getVariadores", {
          id_dispositivo: localStorage.getItem("dispositivos"),
        })
        .then((res) => {
          this.variadores = res.data;
        })
        .finally(() => {
          this.isVariadoresLoaded = true;
        });
    },

    updateDataInterval() {
      if (localStorage.getItem("token") != null) {
        this.getElectrovalvulas();
        this.getSensores();
        // this.getVariadores();
      } else {
        //clear interval
        clearInterval(this.interval);
      }
    },
    getWeather(lat, lon) {
      const apiKey = 'a2a98b7e4ede4e6fb4973643241306';
      const url = `https://api.weatherapi.com/v1/forecast.json?key=${apiKey}&q=${lat},${lon}&days=4`;

      axios.get(url)
        .then(response => {
          const current = response.data.current;
          const forecast = response.data.forecast.forecastday.slice(0, 3); // Los próximos 3 días
          const location = response.data.location;
          this.weatherLocation = `${location.name}`;

          if (current.temp_c !== undefined && current.humidity !== undefined && current.wind_kph !== undefined) {
            this.weatherInfo = `${current.temp_c.toFixed(0)}`;
            this.weatherHumidity = `${current.humidity}%`;
            this.weatherAirQuality = `${(current.wind_kph / 3.6).toFixed(2)} m/s`;
          } else {
            this.weatherInfo = null;
          }

          this.weatherForecasts = forecast.map(day => ({
            date: format(new Date(day.date), 'EEE d'),
            temp: day.day.avgtemp_c,
            minTemp: day.day.mintemp_c,
            condition: day.day.condition.text,
            icon: this.getWeatherIconAndColor(day.day.condition.text).icon,
            iconColor: this.getWeatherIconAndColor(day.day.condition.text).color,
            day: this.capitalizeFirstLetter(format(addDays(new Date(day.date), 1), 'EEEE d', { locale: es })),
          }));

          const weatherData = this.getWeatherIconAndColor(current.condition.text);
          this.weatherIcon = weatherData.icon;
          this.weatherColor = weatherData.color;
        })
        .catch(error => {
          console.error("Error fetching weather data:", error);
          this.weatherInfo = "-";
          this.weatherHumidity = "-%";
          this.weatherAirQuality = "-m/s";
        });
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getWeatherIconAndColor(condition) {
      const conditionLower = condition.toLowerCase();

      if (conditionLower.includes('sunny')) {
        return { icon: 'mdi-weather-sunny', color: 'yellow darken-2' };
      } else if (conditionLower.includes('cloudy')) {
        return { icon: 'mdi-weather-cloudy', color: 'cyan lighten-4' };
      } else if (conditionLower.includes('rain') || conditionLower.includes('shower') || conditionLower.includes('drizzle')) {
        return { icon: 'mdi-weather-rainy', color: 'blue darken-4' };
      } else if (conditionLower.includes('thunder') || conditionLower.includes('storm') || conditionLower.includes('outbreak')) {
        if (conditionLower.includes('rain') || conditionLower.includes('shower') || conditionLower.includes('drizzle')) {
          return { icon: 'mdi-weather-lightning-rainy', color: 'blue-grey darken-1' };
        } else {
          return { icon: 'mdi-weather-lightning', color: 'blue-grey darken-1' };
        }
      } else if (conditionLower.includes('snow') || conditionLower.includes('sleet') || conditionLower.includes('flurries')) {
        return { icon: 'mdi-weather-snowy-heavy', color: 'cyan lighten-4' };
      } else if (conditionLower.includes('fog') || conditionLower.includes('mist') || conditionLower.includes('haze')) {
        return { icon: 'mdi-weather-fog', color: 'grey darken-1' };
      }  else if (conditionLower.includes('patchy rain') || conditionLower.includes('patchy drizzle')) {
        return { icon: 'mdi-weather-partly-rainy', color: 'blue lighten-2' };
      } else {
        // Default case
        return { icon: 'mdi-weather-partly-cloudy', color: 'amber darken-1' };
      }
    }

  },

  mounted() {
    this.getCoreData();
  },

  //Se hacen las llamadas antes de iniciar la vista
  beforeMount() {
    this.getVariadores();
    this.getSensores();
    this.getElectrovalvulas();

    //Se inicia el interval para ir actualizando la vista
    this.idInterval = setInterval(this.updateDataInterval, 5000);
  },
  //Se detiene el interval cuando se cierra la vista
  beforeRouteLeave(to, from, next) {
    clearInterval(this.idInterval);
    next();
  },
};
</script>

<style></style>
