<template>
  <div class="px-0 py-0">
    <v-row dense class="d-flex .flex-column align-start justify-space-between">
      <v-col cols="12" md="4" class="col_data">
        <v-card>
          <v-card-title>
            <span class="headline">Gráficos</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <!-- Selector de Remote -->
              <v-autocomplete class="py-0" solo item-color="darkslategrey" @change="selectRemotes($event)"
                :items="getUniqueRemotes" filled chips color="grey darken-2" label="Dispositivo"
                item-text="nombre_remote" item-value="nombre_remote" multiple return-object>
                <template v-slot:selection="data">
                  <v-chip close @click:close="remove(data.item)">
                    <v-avatar color="darkslategrey" left>
                      <v-icon>{{ data.item.icon }}</v-icon>
                    </v-avatar>
                    {{ data.item.nombre_remote }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content> {{ data.item }}</v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-avatar color="teal">
                      <v-icon dark>{{ data.item.icon }}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title> {{ data.item.nombre_remote }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
              <!-- Selector de Parámetro -->
              <v-select class="py-0" :items="getSensorsByRemote" item-text="name" item-value="id" filled chips
                label="Parámetro" solo color="grey darken-2" item-color="darkslategrey"
                @change="selectedParameter($event)" return-object
                v-if="showParameterSelector && selectedRemotes.length > 0">
                <template v-slot:selection="data">
                  <v-icon left large class="ml-2 mr-3">{{ data.item.icon }}</v-icon>
                  {{ data.item.nombre }}
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content> {{ data.item }} </v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-avatar color="teal">
                      <v-icon dark>{{ data.item.icon }}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title> {{ data.item.nombre }} </v-list-item-title>
                      <v-list-item-subtitle> {{ data.item.nombre_remote }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-select>
              <!-- Selector de fechas -->
              <v-row>
                <v-col cols="12" md="6">
                  <v-menu :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y
                    min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="fechaInicio" label="Fecha de inicio" prepend-icon="mdi-calendar" readonly
                        v-bind="attrs" v-on="on" color="teal" clearable @change="handleDateChange"></v-text-field>
                    </template>
                    <v-date-picker color="teal darken-4" v-model="fechaInicio" locale="es-es" :first-day-of-week="1"
                      @change="handleDateChange"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu class="py-0" :close-on-content-click="true" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="fechaFin" color="teal" label="Fecha de fin" prepend-icon="mdi-calendar"
                        readonly v-bind="attrs" v-on="on" clearable></v-text-field>
                    </template>
                    <v-date-picker v-model="fechaFin" locale="es-es" :first-day-of-week="1" color="teal darken-4"
                      @change="handleDateChange"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
        <div class="details-cards" v-if="!$vuetify.breakpoint.smAndDown && selectedRemotes.length > 0">
          <v-card dark class="mt-4 py-3 px-8 red darken-4 d-flex justify-space-between align-center"
            v-if="resumen.tipo_dato == 'max_min'">
            <div class="d-flex align-end">
              <v-icon large> mdi-format-vertical-align-top </v-icon>
              <span class="mt-n4 text-h6 pl-4">Máximo</span>
            </div>
            <div>
              <span class="text-h4">{{ this.name === 'Bateria Remote' ? calcularValorBateria(resumen.maximo, this.version):
    resumen.maximo.toFixed(1) }} </span>
              <span class="text-h4">{{ unidad }}</span>
            </div>
          </v-card>
          <v-card class="mt-2 py-3 px-8 blue darken-4 d-flex justify-space-between align-center" dark
            v-if="resumen.media !== null">
            <div class="d-flex align-end">
              <v-icon large> mdi-format-vertical-align-center </v-icon>
              <span class="mt-n4 text-h6 pl-4">Media</span>
            </div>
            <div>
              <span class="text-h4">{{ this.name === 'Bateria Remote' ? calcularValorBateria(resumen.media, this.version):
    resumen.media.toFixed(1) }} </span>
              <span class="text-h4">{{ unidad }}</span>
            </div>
          </v-card>
          <v-card class="mt-2 py-3 px-8 teal darken-4 d-flex justify-space-between align-center" dark
            v-if="resumen.tipo_dato == 'max_min'">
            <div class="d-flex align-end">
              <v-icon large> mdi-format-vertical-align-bottom </v-icon>
              <span class="mt-n4 text-h6 pl-4">Mínimo</span>
            </div>

            <div>
              <span class="text-h4">{{ this.name === 'Bateria Remote' ? calcularValorBateria(resumen.minimo, this.version):
    resumen.minimo.toFixed(1) }} </span>
              <span class="text-h4">{{ unidad }}</span>
            </div>
          </v-card>
        </div>
      </v-col>
      <v-col cols="12" md="8">
        <v-card :class="this.$vuetify.breakpoint.mobile ? 'pa-0 ma-0 mb-2' : 'pa-4'" :height="chartHeight">
          <div style="height: 5px">
            <v-progress-linear indeterminate color="teal" v-if="isLoading"></v-progress-linear>
          </div>
          <v-card-text style="height: 100%">
            <div class="d-flex justify-center align-center" v-if="parametro == null" style="height: 90%">
              <v-icon left color="teal"> mdi-information-outline </v-icon>
              <span> Selecciona un parámetro </span>
            </div>
            <div class="d-flex justify-center align-center" v-else-if="parametro !== null && data.length == 0"
              style="height: 90%">
              <v-icon left color="red"> mdi-information-outline </v-icon>
              <span> No se han encontrado datos </span>
            </div>
            <!-- Si le pongo v-if a la grafica pierde opciones -->
            <apexchart v-show="data.length > 0" type="area" height="100%" :options="chartOptions" :series="series">
            </apexchart>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Cambio de posición dependiendo si es un movil o una Pc  -->
      <v-col v-if="$vuetify.breakpoint.smAndDown && selectedRemotes.length > 0" class="mb-12">
        <div class="details-cards">
          <v-card dark class="py-3 px-8 red darken-4 d-flex justify-space-between align-center"
            v-if="resumen.tipo_dato == 'max_min'">
            <div class="d-flex align-end">
              <v-icon large> mdi-format-vertical-align-top </v-icon>
              <span class="mt-n4 text-h6 pl-4">Máximo</span>
            </div>
            <div>
              <span class="text-h4">{{ this.name === 'Bateria Remote' ? calcularValorBateria(resumen.maximo, this.version):
    resumen.maximo.toFixed(1) }}</span>
              <span class="text-h4">{{ unidad }}</span>
            </div>
          </v-card>
          <v-card class="mt-2 py-3 px-8 blue darken-4 d-flex justify-space-between align-center" dark
            v-if="resumen.media !== null">
            <div class="d-flex align-end">
              <v-icon large> mdi-format-vertical-align-center </v-icon>
              <span class="mt-n4 text-h6 pl-4">Media</span>
            </div>
            <div>
              <span class="text-h4">{{ this.name === 'Bateria Remote' ? calcularValorBateria(resumen.media, this.version):
    resumen.media.toFixed(1) }} </span>
              <span class="text-h4">{{ unidad }}</span>
            </div>
          </v-card>
          <v-card class="mt-2 py-3 px-8 teal darken-4 d-flex justify-space-between align-center" dark
            v-if="resumen.tipo_dato == 'max_min'">
            <div class="d-flex align-end">
              <v-icon large> mdi-format-vertical-align-bottom </v-icon>
              <span class="mt-n4 text-h6 pl-4">Mínimo</span>
            </div>

            <div>
              <span class="text-h4">{{ this.name === 'Bateria Remote' ? calcularValorBateria(resumen.minimo, this.version):
    resumen.minimo.toFixed(1) }}</span>
              <!-- <span class="text-h4">{{ unidad }}</span> -->
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import apexchart from "vue-apexcharts";
import axios from "axios";
export default {
  props: ["params"],
  components: { apexchart },
  data() {
    return {
      parametro: null,
      isLoading: false,
      showParameterSelector: false,
      selectedRemotes: [],
      unidad: "",
      name: "",
      version: "",
      nombre_remote: null,
      id_tipo_sensor: null,
      resumen: {
        minimo: null,
        maximo: null,
        media: null,
        total: null,
        tipo_dato: null,
      },
      fechaInicio: "",
      fechaFin: "",
      //Datos de la grafica
      data: [],
      series: [],
      // Configuracion de la grafica
      chartOptions: {
        chart: {
          id: "area-datetime",
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: true,
          },
          animations: {
            enabled: false,
          },
        },
        colors: ["#009688", "#FF4444", "#2E93fA", "#66DA26", "#546E7A", "#E91E63"],
        xaxis: {
          type: "datetime",
          axisBorder: { show: false },
          axisTicks: { show: false },
          tickAmount: 4,
          labels: {
            show: false,
            formatter: function (val) {
              let retorno =
                new Date(val).toLocaleDateString() +
                " " +
                new Date(val).toLocaleTimeString("es-Es");
              return retorno;
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          axisBorder: { show: false },
          axisTicks: { show: false },
        },
        markers: {
          size: 0,
        },
        dataLabels: {
          enabled: false,
        },
      },
    };
  },
  methods: {
    calcularValorBateria(valorBateria, version) {
      let valorCalculado;

      if (version === "1.1") {
        if (valorBateria >= 0 && valorBateria < 3500) {
          valorCalculado = 0;
        } else {
          valorCalculado = (0 + (100 - 0) * (valorBateria - 3347) / (4095 - 3347)).toFixed(0) ;
        }

      // Version 1.4, v1.4.1 y otros
      } else {
        if (valorBateria > 2460) {
          valorCalculado = ((0.1509 * valorBateria - 311.32)).toFixed(0) ;
          if (valorCalculado > 100) {
            valorCalculado = 100;
          }
        } else if (valorBateria > 2372) {
          valorCalculado = ((0.4545 * valorBateria - 1058.2)).toFixed(0) ;
        } else if (valorBateria > 2279) {
          valorCalculado = ((0.1612 * valorBateria - 362.58)).toFixed(0) ;
        } else {
          valorCalculado = 0;
        }
      } 
      return valorCalculado;
    },


    //Se elimina chip de remote seleccionado
    remove(item) {
      if (Array.isArray(this.selectedRemotes)) {
        const index = this.selectedRemotes.indexOf(item);
        if (index >= 0) this.selectedRemotes.splice(index, 1);
        this.data = [];
        this.series = [];
        this.unidad = "";
        this.name = "";
        this.resumen = {
          minimo: null,
          maximo: null,
          media: null,
          total: null,
          tipo_dato: null,
        };
      }
    },

    //Se cargan los datos de los remotes seleccionados
    selectRemotes(remotes) {
      this.selectedRemotes = remotes;
      this.showParameterSelector = true;
    },

    selectedParameter(param) {
      this.parametro = param;
      this.isLoading = true;
      axios
        .post(`${process.env.VUE_APP_API_URL}/getData`, {
          fecha_inicio: this.fechaInicio,
          fecha_fin: this.fechaFin,
          id: param.id,
          id_dispositivo: localStorage.getItem("dispositivos"),
          tipo_param: "sensor",
        })
        .then((res) => {
          this.unidad = res.data.unidades;
          this.name = res.data.nombre;
          this.data = res.data.data;
          this.resumen = res.data.resumen;
          this.version = res.data.version;
          this.id_tipo_sensor = res.data.id_tipo_sensor;
          this.changeOptions();
        })
        .catch(() => {
          this.unidad = null;
          this.name = null;
          this.data = [];
          this.resumen = {
            minimo: null,
            maximo: null,
            media: null,
            total: null,
            tipo_dato: null,
          };
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    handleDateChange() {
      if (this.parametro != null) {
        this.selectedParameter(this.parametro);
      }
    },

    //Funcion para cambiar detalles de la grafica
    changeOptions() {
      let unidad = this.unidad ? this.unidad : "";
      let media = this.resumen.media === null ? "" : this.resumen.media;
      //Actualizo los datos de la grafica si el tipo de sensor es Bateria Remote
      if (this.id_tipo_sensor == 19){
        this.series = [{ name: this.name, data: this.data.map((item) => [item[0], this.calcularValorBateria(item[1], this.version)]) }];

      }else{

        this.series = [{ name: this.name, data: this.data }];
      }

      this.chartOptions = {
        annotations: {
          //Linea que representa la media en la grafica
          yaxis: [
            {
              y: media,
              borderColor: "blue",
              label: {
                show: true,
                text: "Media",
                style: {
                  color: "white",
                  background: "teal",
                },
                position: "left", // Posiciona la etiqueta en el lado izquierdo

              },
            },
          ],
        },
        yaxis: {
          labels: {
            show: true,
            //Formateo el para tener solo 1 decimal y le añado la unidad
            formatter: function (val) {
              return val ? val.toFixed(1) + unidad : val;
            },
          },
        },
        xaxis: {
          labels: {
            show: true,
          },
        },
      };


      //Añado parametros extra a la grafica segun el nombre del tipo_sensor
      // Bateria Remote
      if (this.id_tipo_sensor == 19) {
        const parametersExtraChart = {
          yaxis: {
            min: 0,
            max: 100,
            labels: {
              show: true,
              // Envio el valor de la bateria a la funcion para calcular el valor de la bateria en porcentaje
              formatter: function (val) {            
                return val ? val.toFixed(0) + unidad : val;
              },
            },
          }
        }
        // Añado los parametros extra a la grafica
        this.chartOptions = { ...this.chartOptions, ...parametersExtraChart }
      } 
      // Temperatura
      else if (this.id_tipo_sensor == 17) {
        const parametersExtraChart = {
          yaxis: {
            min: this.resumen.minimo < -10 ? this.resumen.minimo : 0,
            // Si el maximo es mayor a 45, se pone 45, si no se pone el maximo
            max: this.resumen.maximo > 45 ? this.resumen.maximo : 45,
            labels: {
              show: true,
              //Formateo el para tener solo 1 decimal y le añado la unidad
              formatter: function (val) {
                return val ? val.toFixed(0) + unidad : val;
              },
            },
          }
        }

        // Añado los parametros extra a la grafica
        this.chartOptions = { ...this.chartOptions, ...parametersExtraChart }

      } 
      // Humedad
      else if (this.id_tipo_sensor == 16) {
        const parametersExtraChart = {
          yaxis: {
            min: 0,
            max: 100,
            labels: {
              show: true,
              //Formateo el para tener solo 1 decimal y le añado la unidad
              formatter: function (val) {
                return val ? Math.abs(val.toFixed(0)) + unidad : val;
              },
            },
          }
        }

        // Añado los parametros extra a la grafica
        this.chartOptions = { ...this.chartOptions, ...parametersExtraChart }


      } 
      // PH
      else if (this.id_tipo_sensor == 18) {
        const parametersExtraChart = {
          yaxis: {
            min: 3,
            max: 9,
            labels: {
              show: true,
              //Formateo el para tener solo 1 decimal y le añado la unidad
              formatter: function (val) {
                return val ? val.toFixed(0) + unidad : val;
              },
            },
          }
        }

        // Añado los parametros extra a la grafica
        this.chartOptions = { ...this.chartOptions, ...parametersExtraChart }

      } 
      // Presion
      else if (this.id_tipo_sensor == 10) {

        const parametersExtraChart = {
          yaxis: {
            min: 0,
            max: 20,
            labels: {
              show: true,
              //Formateo el para tener solo 1 decimal y le añado la unidad
              formatter: function (val) {
                return val ? Math.abs(val.toFixed(0)) + unidad : val;
              },
            },
          }
        }

        // Añado los parametros extra a la grafica
        this.chartOptions = { ...this.chartOptions, ...parametersExtraChart }

      } 
      // Conductividad
      else if (this.id_tipo_sensor == 21) {
        const parametersExtraChart = {
          yaxis: {
            min: 0,
            max: 100,
            labels: {
              show: true,
              //Formateo el para tener solo 1 decimal y le añado la unidad si no es null
              formatter: function (val) {
                return val ? Math.abs(val.toFixed(0)) + unidad : val;
              },
            },
          }
        }

        // Añado los parametros extra a la grafica
        this.chartOptions = { ...this.chartOptions, ...parametersExtraChart }

      } 
      // Temperatura Hoja
      
      else if (this.id_tipo_sensor == 22) {
        const parametersExtraChart = {
          yaxis: {
            min: this.resumen.minimo < -10 ? this.resumen.minimo : 0,
            // Se mira en los datos el maximo, si es mayor a 45, se pone ese valor, sino se pone 45
            max: this.resumen.maximo > 45 ? this.resumen.maximo : 45,

            labels: {
              show: true,
              //Formateo el para tener solo 1 decimal y le añado la unidad
              formatter: function (val) {
                return val ? val.toFixed(0) + unidad : val;
              },
            },
          }
        }

        // Añado los parametros extra a la grafica
        this.chartOptions = { ...this.chartOptions, ...parametersExtraChart }
      } 
      // Humedad Hoja
      else if (this.id_tipo_sensor == 20) {
        const parametersExtraChart = {
          yaxis: {
            min: 0,
            max: 1000,
            labels: {
              show: true,
              //Formateo el para tener solo 1 decimal y le añado la unidad
              formatter: function (val) {
                return val ? val.toFixed(0) + unidad : val;
              },
            },
          }
        }

        // Añado los parametros extra a la grafica
        this.chartOptions = { ...this.chartOptions, ...parametersExtraChart }
      } 
      // Nivel Agua
      else if (this.id_tipo_sensor == 23) {
        console.log("Entro a la condicion de sensor 23");
        const parametersExtraChart = {
          yaxis: {
            min: 0,
            max: 2.5,
            labels: {
              show: true,
              //Formateo el para tener solo 1 decimal y le añado la unidad
              formatter: function (val) {
                return val ? val.toFixed(1) + unidad : val;
              },
            },
          }
        }

        // Añado los parametros extra a la grafica
        this.chartOptions = { ...this.chartOptions, ...parametersExtraChart }
      }

      this.isLoading = false;
    },
  },
  computed: {
    chartHeight() {
      return this.$vuetify.breakpoint.smAndDown ? "500px" : "75vh";
    },

    getUniqueRemotes() {
      let Remotes = [

      ];
      this.params.forEach(param => {
        if (!Remotes.some(remote => remote.id === param.id_remote)) {
          Remotes.push({
            'id': param.id_remote,
            'nombre_remote': param.nombre_remote ?? 'Core',
            'icon': param.id_remote ? 'mdi-chip' : 'mdi-box-shadow'
          })
        }
      });
      return Remotes;
    },

    getSensorsByRemote() {
      const selectedRemoteIds = this.selectedRemotes.map(selectedRemotes => selectedRemotes.id);
      // console.log("IDs de Remotes", selectedRemoteIds);    

      let parametros = [
      ];

      for (const sensor of this.params) {
        if (selectedRemoteIds.length === 0 && sensor.id_remote === null) {
          parametros.push(sensor);
        } else {
          for (const remoteId of selectedRemoteIds) {
            if (sensor.id_remote === remoteId) {
              parametros.push(sensor);
            }
          }
        }
      }
      return parametros;
    }
  },
  beforeMount() {
    // Obtenemos la fecha de mañana en formato "yyyy-mm-dd"
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow = tomorrow.toISOString().split("T")[0];

    // Obtenemos la fecha de hace 7 dias en formato "yyyy-mm-dd"
    let lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);
    lastWeek = lastWeek.toISOString().split("T")[0];

    // Asignamos las fechas a los campos de fecha
    this.fechaInicio = lastWeek;
    this.fechaFin = tomorrow;
  }
};
</script>

<style></style>
