<template>
  <GruposComponent
    :electrovalvulas="electrovalvulas"
    :grupos="grupos"
    :loader="loader"
    @updateGroup="updateGroup"
  />
</template>

<script>
import GruposComponent from "@/components/GruposComponent.vue";
import axios from "axios";
export default {
  components: { GruposComponent },
  data() {
    return {
      loader: false,
      electrovalvulas: [],
      grupos: [],
    };
  },
  beforeMount() {
    this.loader = true;
    axios
      .post(
        process.env.VUE_APP_API_URL + "/getElectrovalvulas",
        { id_dispositivo: localStorage.getItem("dispositivos") },
         
      )
      .then((res) => (this.electrovalvulas = res.data))
      .catch((err) => console.error(err))
      .finally(() => {
        axios
          .post(
            process.env.VUE_APP_API_URL + "/getGrupos",
            { id_dispositivo: localStorage.getItem("dispositivos") },
             
          )
          .then((res) => (this.grupos = res.data))
          .catch((err) => console.error(err))
          .finally(() => {
            this.loader = false;
          });
      });
  },
  methods: {
    updateGroup() {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/getGrupos",
          { id_dispositivo: localStorage.getItem("dispositivos") },
           
        )
        .then((res) => (this.grupos = res.data))
        .catch((err) => console.error(err))
        .finally(() => {
          this.isLoaded = true;
        });
    },
  },
};
</script>

<style>
</style>