import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardView from '../views/DashboardView'
// import ConfiguracionView from '../views/ConfiguracionView'
import GruposView from '../views/GruposView'
import ProgramacionesView from '../views/ProgramacionesView'
import AnalisisView from '../views/AnalisisView'
import ContadoresView from '../views/ContadoresView'
import InformesView from '../views/InformesView'
// import AlarmasView from '../views/AlarmasView'
import UsuarioView from '../views/UsuarioView'
import LoginView from '../views/LoginView'

Vue.use(VueRouter)

const router =  new VueRouter({
    mode:'history',
    routes:[
        {
            path:'/Login',
            name:'Login',
            component:LoginView
        },
        {
            path:'/Panel%20de%20control',
            name:'Panel%20de%20control',
            component:DashboardView
        },
        {
            path:'/Dashboard',
            name:'Panel%20de%20control',
            component:DashboardView
        },
        // {
        //     path:'/Configuracion',
        //     name:'Configuracion',
        //     component:ConfiguracionView
        // },
        {
            path:'/Grupos',
            name:'Grupos',
            component:GruposView
        },
        {
            path:'/Programaciones',
            name:'Programaciones',
            component:ProgramacionesView
        },
        {
            path:'/Analisis',
            name:'Analisis',
            component:AnalisisView
        },
        {
            path:'/Contadores',
            name:'Contadores',
            component:ContadoresView
        },
        {
            path:'/Informes',
            name:'Informes',
            component:InformesView
        },
        // {
            // path:'/Alarmas',
            // name:'Alarmas',
            // component:AlarmasView
        // },
        {
            path:'/',
            redirect:'/Login'
        },
        {
            path:'/Usuario',
            name: 'Usuario',
            component:UsuarioView
        }
    ]
})

export default router