<template>
  <div>
    <v-card-title>
      <span class="headline pa-2">Variadores</span>
      <v-spacer></v-spacer>
      <v-icon v-show="!show_variator" class="ml-10" @click="showVariadorInfoClick()">mdi-menu-down-outline</v-icon>
      <v-icon v-show="show_variator" @click="closeButtonClick()">mdi-window-close</v-icon>
    </v-card-title>

    <v-select v-if="variador && show_variator" v-model="variador" :items="variadores" filled chips class="mb-4 pa-4" color="blue-grey lighten-2"
      label="Selecciona un variador" item-text="nombre" item-value="id" solo item-color="darkslategrey"
      @change="selectedVariador($event)" hide-details="auto">
      <template v-slot:selection="data">
        <v-icon left large class="ml-2 mr-3">mdi-gauge</v-icon>
        {{ data.item.nombre }}
      </template>
      <template v-slot:item="data">
        <template>
          <v-list-item-avatar color="teal">
            <v-icon color="white">mdi-gauge</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title> {{ data.item.nombre }} </v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-select>

    <v-list dense flat :class="this.$vuetify.breakpoint.mobile ? 'pa-3 ma-1' : 'pa-3 pt-1'" v-if="variador && show_variator" >
      <v-subheader>PARÁMETROS</v-subheader>
      <v-divider class="mt-n2"></v-divider>
      <v-list-item-group color="teal" class="pa-0">
        <v-list-item three-line class="pa-0" v-for="(param, i) in variador.parametros" :key="i">
          <v-list-item-icon class="mt-7">
            <v-icon color="teal darken-3" dark>{{ param.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> {{ param.descripcion }} </v-list-item-title>
          </v-list-item-content>
          <v-spacer></v-spacer>
          <!-- Para el parametro de encendido -->
          <div>
            <v-chip v-if="param.tipo_valor === 'encendido_dual'" @click="selected(param)" label dark small :color="param.is_write == '1' && param.valor_actual != '0'
              ? 'teal darken-1'
              : ''
              ">
              <span v-if="param.valor_actual == '0'">Off</span>
              <span v-else>On
                <v-icon class="" small>{{
                  nombreValor(param) === "Solar"
                  ? "mdi-white-balance-sunny"
                  : "mdi-flash"
                }}</v-icon></span>
            </v-chip>
            <v-chip color="red darken-3" v-if="param.valor_actual == 'error'">
              <v-icon small color="white">
                mdi-alert-circle-outline
              </v-icon>
            </v-chip>
            <v-chip v-else-if="param.valor_actual == null">
              <v-progress-circular size="14" width="2" indeterminate color="teal darken-4"></v-progress-circular>
            </v-chip>
            <v-chip v-else @click=" param.is_write ? selected(param) : ''" label dark small :color="param.is_write == 1 && param.valor_actual > 0
              ? 'teal darken-1' : ''">
              <span v-if="param.valor_actual == 'error'">
                <v-icon x-small>
                  mdi-alert-circle-outline
                </v-icon>
              </span>
              <span v-else>{{ nombreValor(param) }}</span>
            </v-chip>
          </div>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <!-- POPUPS -->
    <v-dialog v-model="dialog" v-if="selectedParam != null" max-width="550" persistent>
      <v-card>
        <v-card-title> Modificar {{ selectedParam.descripcion }} </v-card-title>
        <v-card-text class="pb-0">
          <v-row class="" justify="space-between">
            <!-- PopUp si el tipo es un select -->
            <v-col v-if="selectedParam.tipo_valor === 'select'" class="mt-4">
              <v-select :items="selectedParam.opciones_nombres.split(',')" filled chips color="blue-grey lighten-2"
                label="Selecciona una opción" item-text="nombre" item-value="nombre" solo item-color="darkslategrey"
                @change="
                  setData(
                    selectedParam.opciones_nombres.split(',').indexOf($event)
                  )
                  ">
                <template v-slot:selection="data">
                  <v-icon left large class="ml-2 mr-3">mdi-power-cycle</v-icon>
                  {{ data.item }}
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-avatar color="teal">
                      <v-icon dark>mdi-power-cycle</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title> {{ data.item }} </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-select>
            </v-col>
            <!-- PopUp si es un encendido Dual -->
            <v-col v-if="selectedParam.tipo_valor === 'encendido_dual'" class="">
              <v-container class="d-flex justify-space-around my-4" :class="$vuetify.breakpoint.mobile ? 'align-start' : 'align-center'
                ">
                <v-container class="d-flex flex-column justify-center align-center">
                  <v-btn color="warning" fab large class="mb-2" :outlined="outlinedSolar" @click="setEncendido('Solar')">
                    <v-icon color="">mdi-white-balance-sunny</v-icon>
                  </v-btn>
                  <span>Encendido solar</span>
                </v-container>
                <v-container class="d-flex flex-column align-center justify-center">
                  <v-btn color="blue" fab large class="mb-2" :outlined="outlinedElectrico"
                    @click="setEncendido('Electrico')">
                    <v-icon :color="outlinedElectrico ? 'blue' : 'white'" class="d-flex flex-column">mdi-flash</v-icon>
                  </v-btn>
                  <span>Encendido electrico</span>
                </v-container>
                <v-container class="d-flex flex-column align-center justify-center">
                  <v-btn color="red" fab large class="mb-2" :outlined="outlinedApagar" @click="setEncendido('Off')">
                    <v-icon :color="outlinedApagar ? 'red' : 'white'" class="d-flex flex-column">mdi-power</v-icon>
                  </v-btn>
                  <span>Apagar</span>
                </v-container>
              </v-container>
            </v-col>
            <!-- PopUp si es un slider -->
            <v-col v-if="selectedParam.tipo_valor === 'slider'" class="mt-4">
              <span class="display-3 font-weight-light">{{ valorParametro }} {{ selectedParam.unidades }}</span>
              <v-slider color="teal darken-1" v-model="valorParametro" step="0.1" min="0" track-color="grey"
                :max="selectedParam.opciones_valores.split(',')[1]">
              </v-slider>
            </v-col>
            <!-- PopUp si es un on/off -->
            <v-col v-if="selectedParam.tipo_valor === 'off,on'">
              <v-card-subtitle>
                <span class="font-weight-bold"><v-icon left color="warning">mdi-alert-outline</v-icon>¿Esta
                  seguro de que desea cambiar el estado a "{{
                    indexNombre(selectedParam) == 1
                    ? selectedParam.opciones_nombres.split(",")[0]
                    : selectedParam.opciones_nombres.split(",")[1]
                  }}"?</span>
              </v-card-subtitle>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions :class="loading ? 'pb-0 px-0' : ''">
          <v-spacer></v-spacer>
          <div v-if="!loading">
            <v-btn text @click="runOrderInInverter"> Aceptar </v-btn>
            <v-btn color="red darken-1" text @click="close"> Cancelar </v-btn>
          </div>
          <v-progress-linear v-else class="mt-5" indeterminate color="teal"></v-progress-linear>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["variadoresData", "valvula", "sensorSelectedOnMap"],
  data() {
    //Se inicia el Select con el primer variador del array
    let variador = this.variadoresData[0];
    return {
      dialogConfirm: false,
      dialog: false,
      variador,
      parametros: null,
      selectedParam: null,
      opcionesNombres: null,
      valorParametro: null,
      outlinedElectrico: true,
      outlinedSolar: true,
      outlinedApagar: true,
      idInterval: null,
      loading: false,
      show_variator: false,
    };
  },
  computed: {
    variadores() {
      return this.variadoresData
    },
    variatorHeight() {
      if (this.$vuetify.breakpoint.xl) {
        return this.$vuetify.breakpoint.xl ? (this.show_variator ? "500px" : "0px") : "54vh";
      } else {
        return this.$vuetify.breakpoint.mobile ? (this.show_variator ? "360px" : "0px") : "66vh";
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  watch: {
     // Watch que se ejecuta cada vez que se pulsa sobre un sensor
     sensorSelectedOnMap: function (newVal) {
      if (newVal != null) {
        this.show_variator = true;
      }
    },
    // Watch que se ejecuta cada vez que se pulsa sobre una valvula
    valvula: function (newVal) {
      if (newVal != -1) {
        this.show_variator = true;
      }

    },
  },
  methods: {
    getValueToWriteInParam(valor) {
      try {

        let minX = 0;
        let maxX = 65025;
        let midX;

        while (minX <= maxX) {
          midX = Math.floor((minX + maxX) / 2);
          let calculatedY = eval(this.selectedParam.conversion_front_end.replace(/x/g, midX))

          calculatedY = calculatedY.toFixed(2)

          console.log(calculatedY, valor)
          if (calculatedY == valor) {
            return midX; // Encontramos el valor de x
          } else if (calculatedY < valor) {
            minX = midX + 1; // Descartamos la mitad inferior del rango
          } else {
            maxX = midX - 1; // Descartamos la mitad superior del rango
          }
        }

        return midX; // Se devuelve el valor mas cercano encontrado

      }
      catch (err) {
        console.log(err)
      }
    },
    // Selector de variador
    selectedVariador(e) {
      this.variador = this.variadores.find((variador) => variador.id == e);
      this.parametros = this.variador.parametros;
      this.checkParamValues();
    },
    // Funcion para cerrar dialog
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.valorParametro = null;
        this.selectedParam = null;
      }, 250);
    },
    //Actualizar el valor MQTT
    runOrderInInverter() {
      this.loading = true;
      let valor = null;
      if (this.selectedParam.tipo_valor === "off,on") {
        if (this.indexNombre(this.selectedParam) === 1) {
          valor = this.selectedParam.opciones_valores.split(",")[0];
        } else {
          valor = this.selectedParam.opciones_valores.split(",")[1];
        }
      } else {
        valor = this.getValueToWriteInParam(this.valorParametro);
      }
      //Datos para enviar al API
      let bodyFormData = new FormData();
      bodyFormData.append("function", "runOrderInInverter");
      bodyFormData.append("id_param", this.selectedParam.id);
      bodyFormData.append("id_variador", this.selectedParam.id_variador);
      bodyFormData.append("is_write", "1");
      bodyFormData.append("value_to_write", valor);
      bodyFormData.append("is_read", "0");
      axios({
        url: process.env.VUE_APP_API_MQTT_URL,
        method: "POST",
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.$emit(
              "updateParametro",
              this.selectedParam.id_variador,
              this.selectedParam.id,
              eval(this.selectedParam.conversion_front_end.replace(/x/g, valor)),
              this.selectedParam.tipo_valor
            );
          } else {
            this.selectedParam.valor_actual = 'error';
          }
        })
        .finally(() => {
          this.dialog = false;
          this.loading = false;
        });
    },
    // Funcion que se ejecuta cuando se selecciona un parametro
    selected(parametro) {
      if (parametro.valor_actual == 'error' || parametro.valor_actual == null || parametro.is_write === "0") return -1;

      if (parametro.tipo_valor === "encendido_dual") {
        if (this.nombreValor(parametro) == "Solar") {
          this.outlinedSolar = false;
          this.outlinedElectrico = true;
          this.outlinedApagar = true;
        } else if (this.nombreValor(parametro) == "Electrico") {
          this.outlinedElectrico = false;
          this.outlinedSolar = true;
          this.outlinedApagar = true;
        } else if (this.nombreValor(parametro) == "Apagar") {
          this.outlinedElectrico = true;
          this.outlinedSolar = true;
          this.outlinedApagar = false;
        }
      }
      this.selectedParam = parametro;
      this.opcionesNombres = this.selectedParam.opciones_nombres.split(",");
      this.dialog = true;
      this.valorParametro = parametro.valor_actual;
    },
    //Valores para un select
    setData(index) {
      this.valorParametro =
        this.selectedParam.opciones_valores.split(",")[index];
    },
    // Funcion que devuelve el nombre del parametro
    nombreValor(variador) {
      if (
        variador.opciones_valores != null &&
        variador.opciones_nombres != null &&
        variador.opciones_nombres !== "" &&
        variador.opciones_valores !== ""
      ) {
        const arrayValores = variador.opciones_valores.split(",");
        const index = arrayValores.indexOf(variador.valor_actual.toString());
        const arrayNombres = variador.opciones_nombres.split(",");
        return arrayNombres[index];
      }
      if (typeof variador.valor_actual === "string") {
        return (
          variador.valor_actual.replace(".", ",") + " " + variador.unidades
        );
      } else {
        return (
          variador.valor_actual.toString().replace(".", ",") +
          " " +
          variador.unidades
        );
      }
    },
    indexNombre(variador) {
      const arrayValores = variador.opciones_valores.split(",");
      const index = arrayValores.indexOf(variador.valor_actual.toString());
      return index;
    },
    // Set values en el encendido dual ( Solar, Electrico )
    setEncendido(nombre) {
      const arrNombres = this.selectedParam.opciones_nombres.split(",");
      const index = arrNombres.indexOf(nombre);
      const value = this.selectedParam.opciones_valores.split(",")[index];
      if (nombre == "Solar") {
        this.outlinedSolar = false;
        this.outlinedElectrico = true;
        this.outlinedApagar = true;
        this.valorParametro = value;
      } else if (nombre == "Electrico") {
        this.outlinedElectrico = false;
        this.outlinedSolar = true;
        this.outlinedApagar = true;
        this.valorParametro = value;
      } else if (nombre == "Off") {
        this.outlinedApagar = false;
        this.outlinedSolar = true;
        this.outlinedElectrico = true;
        this.valorParametro = value;
      }
    },
    // Funcion para obtener el valor de los parametros
    checkParamValues() {
      this.variador.parametros.forEach((parametro) => {
        let bodyFormData = new FormData();
        bodyFormData.append("function", "runOrderInInverter");
        bodyFormData.append("id_variador", this.variador.id);
        bodyFormData.append("id_param", parametro.id);
        bodyFormData.append("is_write", "0");
        bodyFormData.append("value_to_write", "0");
        bodyFormData.append("is_read", "1");
        if (parametro.valor_actual == "error") parametro.valor_actual = null;
        axios({
          url: process.env.VUE_APP_API_MQTT_URL,
          method: "POST",
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          timeout: 20000,
        })
          .then((res) => {
            if (res.data.success) {
              if (parametro.opciones_nombres != null && parametro.opciones_nombres != "") {
                // Se obtiene el valor del parametro a partir del valor obtenido del variador por mqtt
                if (res.data.data[1] == 1) {
                  parametro.valor_actual = parametro.opciones_valores.split(",")[1];
                }
                else if (res.data.data[1] == 0) {
                  parametro.valor_actual = parametro.opciones_valores.split(",")[0];
                } else {
                  const arrayValores = parametro.opciones_valores.split(",");
                  const index = arrayValores.indexOf(res.data.data[1].toString());
                  parametro.valor_actual = arrayValores[index];
                }
              } else {
                const conversion = parametro.conversion_front_end.replace(/x/g, res.data.data[1]);
                parametro.valor = res.data.data[1]
                parametro.valor_actual = eval(conversion);
                parametro.valor_actual = parametro.valor_actual.toFixed(1);
                parametro.valor_actual = parseFloat(parametro.valor_actual);
              }
            } else {
              parametro.valor_actual = 'error';
            }
          })
          .catch(() => {
            parametro.valor_actual = 'error';
          });
      });
    },
    showVariadorInfoClick() {
      this.show_variator = true;
    },
    closeButtonClick() {
      this.show_variator = false;
    }
  },
  beforeMount() {
    this.checkParamValues();
    //Se obtienen los valores de los parametros cada minuto
    this.idInterval = setInterval(() => {
      this.checkParamValues();
    }, 60000);
    // console.log(this.variador);
  },
  //Se detiene el interval cuando se cierra la vista
  beforeDestroy() {
    clearInterval(this.idInterval);
  },
};
</script>

<style></style>