<template>
    <!-- Dialog por cada vez que se presione el botón de las válvulas-->
    <v-card height="415">
        <v-card dark class="darken-4 card-with-bg rounded-b-xl" height="205">
            <!-- Boton de cerrar dialogo -->
            <div class="d-flex align center justify-end pt-6 pr-4">
                <v-icon @click="closeDialogValveClick()">mdi-window-close</v-icon>
            </div>
            <!-- Nombre de la valvula -->
            <div class="my-4">
                <v-text-field autocomplete="off" :readonly="readonly" solo hide-details :flat="readonly"
                    v-model="nombreValve" id="input-nombre" style="font-size: 28px; font-weight: bold;"
                    background-color="transparent" class="centered-input"></v-text-field>
            </div>
            <!-- Circulo de estado -->
            <div class="d-flex align-center justify-center">
                <v-fade-transition>
                    <div style="position: relative; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); 
              width: 200px; height: 200px; border-radius: 50%; background-color: white;">
                        <v-fade-transition leave-absolute>
                            <v-progress-circular v-if="valveLoader" size="200" width="10" indeterminate color="teal">
                                <div class="d-flex flex-column align-center">
                                    <span class="black--text" style="font-size: 18px">Enviando datos...</span>
                                </div>
                            </v-progress-circular>
                            <v-icon size="200" class="d-flex flex-column align-center" v-else-if="mqttFail" color="red">
                                mdi-close-circle
                            </v-icon>
                            <v-icon size="200" class="d-flex flex-column align-center"
                                v-else-if="!valveLoader && mqttSuccess" color="teal">
                                mdi-check-circle
                            </v-icon>
                        </v-fade-transition>
                        <!-- Circulo mostrando solo el estado (no girando) -->
                        <v-progress-circular v-if="!valveLoader && !mqttSuccess && !mqttFail" :size="200" :rotate="270"
                            :width="10" :style="{ left: '50%', transform: 'translateX(-50%)' }"
                            :color="currentStateValve >= 1 ? 'teal' : 'grey lighten-3'"
                            :value="currentStateValve >= 1 ? 100 : 0">
                            <div class="d-flex flex-column align-center">
                                <v-icon x-large left :color="currentStateValve == 1 ? 'teal darken-2' : 'grey darken-2'"
                                    class="pl-2">{{
                    getIconClass(currentStateValve) }} </v-icon>
                                <span class="black--text" style="font-size: 28px">{{ currentStateValve >= 1 ? 100 : 0
                                    }}%</span>
                            </div>
                        </v-progress-circular>

                    </div>
                </v-fade-transition>
            </div>
        </v-card>
        <!-- Botones de abrir y cerrar y manual -->
        <div class="pt-14 mt-6">
            <v-list-item class="mt-14">
                <v-btn class="pa-4" :class="isAuto ? 'teal darken-3 my-5' : 'warning my-5'" :dark="isAuto == 1" small
                    @click="openDialog" :disabled="is_button_disabled">
                    <v-icon left>
                        {{ isAuto ? "mdi-alpha-a-circle" : "mdi-alpha-m-circle" }}
                    </v-icon>
                    {{ isAuto ? "AUTO" : "MANUAL" }}
                </v-btn>
                <v-list-item-subtitle class="d-flex justify-end align-end">
                    <!--Boton cerrar-->
                    <v-btn class="pa-3" outlined color="red darken-4" @click="setLatch(0)"
                        :disabled="is_button_disabled" v-if="!isAuto">Cerrar</v-btn>
                    <!--Boton Abrir-->
                    <v-btn class="ml-4 pa-4" color="teal darken-2" @click="setLatch(1)" :disabled="is_button_disabled"
                        :dark="!is_button_disabled" v-if="!isAuto">Abrir</v-btn>
                </v-list-item-subtitle>
            </v-list-item>
        </div>

        <!-- Alerta de cambio de estado -->
        <v-dialog v-model="show_dialog" width="520">
            <v-card>
                <v-card-title class="headline subtitle-1">
                    <v-icon color="teal darken-2">mdi-information</v-icon>
                    <h2 class="ml-4"><strong>Cambio de estado</strong></h2>
                </v-card-title>
                <v-card-text class="subtitle-1" v-if="valvula_en_programacion && !isAuto">
                    ¿Estás seguro de cambiar el estado de la válvula a 
                    <strong> {{ isAuto ? " Manual" : " Automático" }}</strong>?
                </v-card-text>
                <v-card-title class="headline" v-if="valvula_en_programacion && isAuto">
                   <v-icon color="red darken-2" class="mr-1">mdi-alert</v-icon>
                    ¡Atención! 
                </v-card-title>

                <v-card-text v-if="valvula_en_programacion && isAuto">
                    No se puede cambiar el estado de la válvula a <strong>Manual</strong> ya que está en la programación 
                    <strong> {{ nombre_programacion }}</strong> del grupo <strong>{{ nombre_grupo }}</strong>
                </v-card-text>

                <v-card-text v-else-if="valvula_en_programacion && !isAuto">
                    <v-icon color="orange darken-4" class="mr-1">mdi-alert</v-icon>
                    <span>Ten en cuenta que esta válvula se activa mediante la programación: 
                        <strong>{{ nombre_programacion }}</strong></span>
                </v-card-text>

                <!-- Si no hay programación -->
                <v-card-text v-else-if="!valvula_en_programacion">
                    ¿Estás seguro de cambiar el estado de la válvula a 
                    <strong> {{ isAuto ? " Manual" : " Automático" }}</strong>?
                </v-card-text>
                <!-- Botones de aceptar y cancelar -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="teal darken-2" text @click="show_dialog = false" v-if="valvula_en_programacion && isAuto">Aceptar</v-btn>
                    <v-btn color="red darken-4" text @click="show_dialog = false" v-if="valvula_en_programacion && !isAuto">Cancelar</v-btn>
                    <v-btn color="teal darken-2" text @click="setIsAuto(); show_dialog = false" v-if="valvula_en_programacion && !isAuto">Aceptar</v-btn>
                    <v-btn color="red darken-4" text @click="show_dialog = false" v-if="!valvula_en_programacion">Cancelar</v-btn>
                    <v-btn color="teal darken-2" text @click="setIsAuto(); show_dialog = false" v-if="!valvula_en_programacion">Aceptar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>
<script>
import axios from "axios";
export default {
    props: {
        selectedValvula: Object,
    },
    data() {
        return {
            nombreValve: this.selectedValvula.nombre,
            currentStateValve: this.selectedValvula.current_state || 0,
            switchStateValve: this.selectedValvula.current_state > 0 ? true : false,
            isAuto: this.selectedValvula.is_auto,
            valveLoader: false,
            mqttSuccess: false,
            mqttFail: false,
            readonly: true,
            show_dialog: false,
            is_button_disabled: false,
            progress_loader: false,
            valvula: this.selectedValvula,
            // valvula_en_grupo: false,
            valvula_en_programacion: false,
            id_grupo: null,
            nombre_programacion: null,
            nombre_grupo: null,
        };
    },

    methods: {
        // Para abrir el dialogo de alerta
        openDialog() {
            this.getGroup();
            this.getProgramaciones();
            this.show_dialog = true;
        },

        //Para cerrar el card del Remote
        closeDialogValveClick() {
            this.$emit('close-dialog');
        },

        // Para obtener el icono de las imágenes para los botones
        getIconClass(currentStateValve) {
            return currentStateValve >= 1 ? 'mdi-valve-open' : 'mdi-valve-closed';
        },

        // Para abrir o cerrar la válvula
        setLatch(state) {
            this.is_button_disabled = true;
            this.valveLoader = true;

            let bodyFormData = new FormData();
            bodyFormData.append("function", "setLatch");
            bodyFormData.append('id_remote', this.valvula.id_remote)
            bodyFormData.append('RS485_address', this.valvula.rs485_address)
            bodyFormData.append('n_valve', this.valvula.n_valvula)
            bodyFormData.append('state', state)
            bodyFormData.append('id_valve', this.valvula.id);
            axios({
                url: process.env.VUE_APP_API_MQTT_URL,
                method: "POST",
                data: bodyFormData,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then((res) => {
                    this.valveLoader = false;
                    if (res.data.success) {
                        this.mqttSuccess = true;
                        this.currentStateValve = state;
                        this.$emit("getElectrovalvulas");
                    } else {
                        this.mqttFail = true;
                        this.switchStateValve = !this.switchStateValve;
                    }
                })
                .catch(() => {
                    this.mqttFail = true;
                    this.switchStateValve = !this.switchStateValve;
                })
                .finally(() => {
                    this.is_button_disabled = false;
                    setTimeout(() => {
                        this.mqttFail = false;
                        this.mqttSuccess = false;
                        this.valveLoader = false;
                        this.progress_loader = false;
                    }, 2000);
                });
        },

        // Para cambiar el estado de la válvula a manual o automático
        setIsAuto() {
            this.valveLoader = true;
            let bodyFormData = new FormData();
            bodyFormData.append("function", "setIsAuto");
            bodyFormData.append('id_remote', this.valvula.id_remote)
            bodyFormData.append("id", this.valvula.id)
            bodyFormData.append("value", !this.isAuto * 1);
            axios({
                url: process.env.VUE_APP_API_MQTT_URL,
                method: "POST",
                data: bodyFormData,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then((res) => {
                    this.valveLoader = false;
                    if (res.data.success) {
                        if (res.data.data != this.isAuto) {
                            this.isAuto = res.data.data;
                        }
                        this.mqttSuccess = true;
                        this.$emit("getElectrovalvulas");
                    } else {
                        this.mqttFail = true;
                    }
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    setTimeout(() => {
                        this.mqttFail = false;
                        this.mqttSuccess = false;
                        this.valveLoader = false;
                    }, 2000);
                });
        },

        // Para obtener el estado de la válvula
        getIsAuto() {
            let bodyFormData = new FormData();
            bodyFormData.append("function", "getIsAuto");
            bodyFormData.append("id_remote", this.valvula.id_remote);
            bodyFormData.append("id", this.valvula.id);
            axios({
                url: process.env.VUE_APP_API_MQTT_URL,
                method: "POST",
                data: bodyFormData,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .catch((err) => console.error(err))
                .finally(() => {

                });
        },

        // Para obtener el nombre de la valvula
        focusInput() {
            this.readonly = !this.readonly
            if (!this.readonly) {
                document.getElementById("input-nombre").focus()
            }
            if (this.readonly) {
                this.nombreValve = this.valvula.nombre
            }
        },

        // Para guardar los cambios del nombre de la válvula
        saveChanges() {
            this.readonly = true;
            if (this.nombreValve != this.valvula.nombre) {
                axios.put(
                    `${process.env.VUE_APP_API_URL}/updateElectrovalvula`,
                    {
                        id: this.valvula.id,
                        nombre: this.nombreValve,
                        latitud: this.valvula.latitud,
                        longitud: this.valvula.longitud,
                        is_auto: this.valvula.is_auto,
                        manual_state: this.valvula.ordered_state * 1
                    }
                )
                    .then(res => {
                        console.log(res);
                    })
                    .finally(() => {
                        this.readonly = true
                    })
            }
            else {
                this.readonly = true
            }
        },

        getGroup() {
            axios
                .post(
                    process.env.VUE_APP_API_URL + "/getGrupos",
                    { id_dispositivo: localStorage.getItem("dispositivos") },

                )
                .then((res) => (
                    // Compruebo si la válvula está en un grupo
                    res.data.forEach(grupo => {
                        grupo.rels.forEach(valvula => {
                            if (valvula.id_electrovalvula == this.valvula.id) {
                                // this.valvula_en_grupo = true;
                                this.id_grupo = grupo.id;
                            }
                        });
                    })
                ))
                .catch((err) => console.error(err))
                .finally(() => {
                    this.isLoaded = true;
                });
        },

        getProgramaciones() {
            axios
                .post(`${process.env.VUE_APP_API_URL}/getProgramaciones`, {
                    id_dispositivo: localStorage.getItem("dispositivos"),
                    id_finca: JSON.parse(localStorage.getItem("id_finca")),
                })
                .then((res) => {
                    // Compruebo si la válvula está en un grupo y si el grupo tiene programaciones
                    res.data.forEach(programacion => {
                        if (programacion.id_grupo == this.id_grupo) {
                            this.valvula_en_programacion = true;
                            this.nombre_programacion = programacion.nombre;
                            this.nombre_grupo = programacion.nombre_grupo;
                        }
                    });
                })
                .catch((err) => console.error(err))
                .finally(() => {
                    // this.done = true;
                });
        },
    },

    beforeMount() {
        this.getGroup();
        this.getProgramaciones();
    },
};

</script>

<style scoped>
.centered-input>>>input {
    text-align: center
}

.card-with-bg {
    background-image: url(../assets/bg.png);
}
</style>
