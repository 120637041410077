<template>
  <v-container class="pa-0" fluid>
    <v-data-table :headers="headers" :items="grupos != null ? grupos : []" sort-by="id"
      class="elevation-1 perfil_row mb-5" hide-default-footer :search="search" :items-per-page="-1" :loading="isLoading"
      loading-text="Cargando..." locale="es-es" no-data-text="No hay grupos registrados">

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Grupos</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- Dialog para añadir o editar algun grupo -->
          <v-dialog v-model="dialog" class="details" :fullscreen="isMobile" :transition="isMobile ? 'v-bottom-sheet-transition' : ''"
          :max-width="isMobile ? '' : '500px'" @keydown.esc="close('dialog')" @click:outside="close('dialog')">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="teal darken-2" dark v-bind="attrs" v-on="on" fab small @click="newItem">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headlines">{{ formTitle }}</span>
                <v-spacer></v-spacer>
                <v-icon @click="close('dialog')">mdi-close</v-icon>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.nombre" label="Nombre de grupo *" rounded outlined class="pt-1"
                        :error-messages="nombreErrors" placeholder="Ej.: Grupo 01, Grupo diurno">
                      </v-text-field>
                    </v-col>
                    <span class="subtitle-2 ml-4 mt-n3">Selecciona las electroválvulas en el plano de la finca o en el
                      selector</span>
                    <v-col cols="12" sm="12" md="12">
                      <v-select v-model="selectedEVs" :items="electrovalvulas" item-text="nombre" item-value="id"
                        class="px-2 mt-n1" label="Electroválvulas seleccionadas" multiple item-color="teal darken-2">
                      </v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-text>
                <!-- Mapa -->
                <v-container>
                  <GmapMap v-if="editedItem.id != null" :center="centerMap" :zoom="15" map-type-id="satellite" :style="isMobile ? 'width: 100%; height: 540px' : 'width: 100%; height: 250px'"
                    class="mt-n7"  :options="{ disableDefaultUI: true, }">
                    <GmapMarker v-for="(m, i) in markers" :key="i" :position="m.position" :icon="m.icon"
                      :label="m.label" :id="m.id" @click="selectedOnMap(m)">
                    </GmapMarker>
                  </GmapMap>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="close('dialog')">
                  Cancelar
                </v-btn>
                <v-btn color="teal darken-1" text @click="save"
                  :disabled="editedItem.nombre.trim() === '' || selectedEVs.length === 0 || nombreExists">
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Dialog para borrar algun grupo -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline subtitle-1">Eliminar {{ editedItem.nombre }}</v-card-title>
              <v-card-text class="subtitle-2" v-if="deleteError">
                ¿Estás seguro de que quieres eliminar el grupo <span class="font-weight-bold">{{ editedItem.nombre
                  }}</span>?
              </v-card-text>
              <v-card-text v-else class="subtitle-2">
                <v-icon color="red darken-2" class="mr-2">mdi-alert-circle</v-icon>
                <span class="font-weight-bold">¡Atención!</span>
                <br>
                <span> No puedes eliminar el grupo <span class="font-weight-bold">{{ editedItem.nombre }}</span> hasta
                  que
                  elimines las programaciones vinculadas a este grupo.</span>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="close('dialogDelete')" v-if="!deleteError">Aceptar</v-btn>
                <v-btn color="teal darken-1" text @click="close('dialogDelete')" v-if="deleteError">Cancelar</v-btn>
                <v-btn color="red darken-1" text @click="deleteItemConfirm()" v-if="deleteError">Confirmar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.slot_electrovalvulas`]="{ item }">
        <v-chip v-for="(ev, key) in getElectrovalvulasDelGrupo(item)" :key="key">
          {{ ev.nombre }}
        </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import valve_closed from "@/assets/valve_closed.png";
import valve_opened from "@/assets/valve_opened.png";
import axios from "axios";

export default {
  props: ["electrovalvulas", "grupos", "loader"],
  data() {
    let markers = [];
    let centerMap = {
      lat: null,
      lng: null,
    };
    if (this.electrovalvulas.length > 0) {
      this.electrovalvulas.map((el) => {
        markers.push({
          position: { lat: el.latitud, lng: el.longitud },
          icon: valve_closed,
          label: el.nombre,
          id: el.id,
        });
      });
    }
    return {
      headers: [
        { text: "Nombre", value: "nombre", class: "primary--text", width: "40%" },
        { text: "Electroválvulas", value: "slot_electrovalvulas", width: "40%", class: "primary--text" },
        { text: "Acciones", value: "actions", sortable: false, class: "primary--text", width: "6%" },
      ],
      nombreExistMessage: "Este nombre de grupo ya está en uso.",
      idGroup: 0,
      selectedEVs: [],
      oldEvs: [],
      search: "",
      isLoading: this.loader,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      deleteError: false,
      dialogSave: false,
      vinculados: [],
      editedItem: {
        id: 0,
        nombre: "",
        rels: [],
      },
      defaultItem: {
        id: 0,
        nombre: "",
        rels: [],
      },
      centerMap,
      markers,
    };
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo grupo" : "Editar grupo";
    },
    nombreErrors() {
      if (this.nombreExists) {
        return [this.nombreExistMessage];
      }
      return [];
    },

    nombreExists() {
      const nombreTrimmed = this.editedItem.nombre.trim().toLowerCase();
      return this.grupos.some(
        grupo => grupo.nombre.trim().toLowerCase() === nombreTrimmed &&
          grupo.id !== this.editedItem.id
      );
    },
  },

  methods: {
    getElectrovalvulasDelGrupo(grupo) {
      return this.electrovalvulas.filter((electrovalvula) =>
        grupo.rels
          .filter((rel) => !(rel.is_deleted > 0))
          .map((rel) => rel.id_electrovalvula)
          .includes(electrovalvula.id)
      );
    },

    newItem() {
      this.editedIndex = -1;
      this.selectedEVs = [];
      let rels = [];
      this.defaultItem.rels = rels;
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    editItem(item) {
      this.selectedEVs = [];
      this.oldEvs = item.rels;
      this.editedIndex = this.grupos.indexOf(item);
      this.dialog = true;
      this.editedItem.nombre = item.nombre;
      this.editedItem.id = item.id;
      item.rels.map((el) => {
        if (el.is_deleted == false) {
          this.selectedEVs.push(el.id_electrovalvula);
          this.markers.find(
            (marker) => marker.id == el.id_electrovalvula
          ).icon = valve_opened;
        }
      });
    },

    deleteItem(item) {
      this.editedIndex = this.grupos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
      this.idGroup = item.id;

      // Verificar si el grupo tiene programaciones vinculadas
      let vinculados = this.vinculados.filter(
        (el) => el.id_grupo == this.editedItem.id
      );

      if (vinculados.length > 0) {
        this.deleteError = false;
      } else {
        this.deleteError = true;
      }
    },

    close(dialog) {
      //Se reinician todas las variables y los marcadores
      if (dialog === "dialog") this.dialog = false;
      if (dialog === "dialogDelete") this.dialogDelete = false;
      this.selectedEVs = [];
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.markers.map((el) => {
        el.icon = valve_closed;
      });
    },

    save() {
      this.selectedEVs.map((el) => {
        let valvula = this.electrovalvulas.find((ev) => ev.id === el);
        let index_ev = this.electrovalvulas.map((ev) => ev.id).indexOf(el);
        let valvulas = {
          id: "n" + index_ev,
          is_deleted: 0,
          id_electrovalvula: valvula.id,
          nombre: valvula.nombre,
        };
        this.editedItem.rels.push(valvulas);
      });
      this.oldEvs.map((el) => {
        let found = this.selectedEVs.find((sv) => sv === el.id_electrovalvula);
        if (found == undefined) {
          let index_ev = this.electrovalvulas
            .map((ev) => ev.id)
            .indexOf(el.id_electrovalvula);
          let deletedValve = {
            id: "n" + index_ev,
            is_deleted: 1,
            id_electrovalvula: el.id_electrovalvula,
            nombre: el.nombre,
          };
          this.editedItem.rels.push(deletedValve);
        }
      });
      this.isLoading = true;
      let dataPost = {
        id_dispositivo: localStorage.getItem("dispositivos").split(",")[0],
        data: {
          id: this.editedItem.id,
          nombre: this.editedItem.nombre,
          electrovalvulas: this.editedItem.rels,
        },
      };
      if (this.editedIndex > -1) {
        axios
          .post(`${process.env.VUE_APP_API_URL}/updateGrupo`, dataPost)
          .then((res) => {
            if (res.status == 200) {
              this.$emit("updateGroup");
            }
          })
          .catch((err) => console.error(err))
          .finally(() => {
            this.isLoading = false;
            this.editedItem.rels = [];
            this.selectedEVs = [];
            this.oldEvs = [];
          });
      } else {
        axios
          .post(`${process.env.VUE_APP_API_URL}/insertGrupo`, dataPost)
          .then((res) => {
            if (res.status == 200) {
              this.$emit("updateGroup");
            }
          })
          .catch((err) => console.error(err))
          .finally(() => {
            this.isLoading = false;
            this.editedItem = Object.assign({}, this.defaultItem);
          });
      }

      this.close("dialog");
    },

    deleteItemConfirm: function () {
      this.isLoading = true;
      const data = {
        id: this.idGroup,
        id_dispositivo: localStorage.getItem("dispositivos").split(",")[0],
      };
      axios
        .put(`${process.env.VUE_APP_API_URL}/deleteGrupo`, data)
        .then(() => {
          this.$emit("updateGroup");
        })
        .catch((err) => console.error(err))
        .finally(() => (this.isLoading = false));
      this.close("dialogDelete");
    },

    selectedOnMap(item) {
      let index = this.selectedEVs.indexOf(item.id);
      if (index === -1) {
        this.selectedEVs.push(item.id);
        this.updateMarkerIcon(item.id, true);
      } else {
        this.selectedEVs.splice(index, 1);
        this.updateMarkerIcon(item.id, false);
      }
    },

    updateMarkerIcon(markerId, isSelected) {
      const markerIndex = this.markers.findIndex(m => m.id === markerId);
      if (markerIndex !== -1) {
        this.markers[markerIndex].icon = isSelected ? valve_opened : valve_closed;
      }
    },

    getProgramaciones() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/getProgramaciones`, {
          id_dispositivo: localStorage.getItem("dispositivos"),
          id_finca: JSON.parse(localStorage.getItem("id_finca")),
        })
        .then((res) => {
          this.vinculados = res.data.filter((el) => el.id_grupo != null);
        })
        .catch((err) => console.error(err))
        .finally(() => {
          // this.done = true;
        });
    },

  },

  watch: {
    loader(val) {
      this.isLoading = val;
    },

    electrovalvulas(val, oldVal) {
      if (val.length != oldVal.length) {
        this.centerMap = {
          lat: this.electrovalvulas[0].latitud,
          lng: this.electrovalvulas[0].longitud,
        }
        this.electrovalvulas.map((el) => {
          this.markers.push({
            position: { lat: el.latitud, lng: el.longitud },
            icon: valve_closed,
            label: {
              text: el.nombre,
              color: "white"
            },
            id: el.id,
          });
        })
      }
    },

    selectedEVs(newVal, oldVal) {
      const newSet = new Set(newVal);
      const oldSet = new Set(oldVal);

      const selected = new Set([...newVal].filter(x => !oldSet.has(x)));
      const deselected = new Set([...oldVal].filter(x => !newSet.has(x)));

      selected.forEach(id => this.updateMarkerIcon(id, true));
      deselected.forEach(id => this.updateMarkerIcon(id, false));
    }

  },

  mounted() {
    this.getProgramaciones();
  },
};
</script>

<style scoped>
.details {
  position: relative;
  overflow-y: auto;
  scrollbar-width: none;
}

.details::-webkit-scrollbar {
  width: 0px;
}

@media (min-width: 598px) {
  .details {
    z-index: 2;
    max-width: 780px;
    height: auto;
    margin: auto;
  }
}

@media (max-width: 599px) {
  .details {
    z-index: 6;
    width: 100%;
    height: 100vh;
    margin: 0;
  }
}
</style>