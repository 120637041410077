<template>
  <div>
    <v-navigation-drawer v-if="!$vuetify.breakpoint.mobile" :mini-variant="mini" permanent clipped dark app
      class="pt-2">
      <v-list dense nav class="py-0">
        <v-list-item-group v-model="selectedItemMenu" mandatory>
          <v-list-item v-for="(item, i) in filteredItemsNav" :key="i" @click="linkTo(item.title)">
            <v-list-item-icon>
              <v-icon> {{ item.icon }} </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{ item.title }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-bottom-navigation v-model="selectedItemMenu" v-if="$vuetify.breakpoint.mobile" fixed dark color="white">
      <div style="display: flex; overflow-x: auto; overflow-y: hidden; scrollbar-color: transparent;">
        <v-btn v-for="(item, i) in filteredItemsNav" v-bind:key="i" @click="linkTo(item.title)">
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
        </div>
    </v-bottom-navigation>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "NavigationDrawer",
  props: {
    mini: Boolean,
  },
  data() {
    return {
      selectedItemMenu: 0,
      hasContadores: false,
      itemsNav: [
        {
          title: "Panel de control",
          icon: "mdi-view-dashboard",
        },
        {
          title: "Contadores",
          icon: "mdi-watch-vibrate",
        },
        {
          title: "Grupos",
          icon: "mdi-ungroup",
        },
        {
          title: "Programaciones",
          icon: "mdi-calendar-blank-multiple",
        },
        {
          title: "Análisis",
          icon: "mdi-chart-line",
        },
        {
          title: "Informes",
          icon: "mdi-file-document-multiple-outline",
        },
        // {
        //   title: "Configuración",
        //   icon: "mdi-view-list",
        // },
        // {
        //   title: "Alarmas",
        //   icon: "mdi-bell-outline",
        // }
      ],
    };
  },
  computed: {
    filteredItemsNav() {
      if (this.hasContadores) {
        return this.itemsNav;
      } else {
        return this.itemsNav.filter(item => item.title !== 'Contadores');
      }
    }
  },
  methods: {
    linkTo: function (url) {
      //Regex para eliminar las tildes
      url = url.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      // Regex para eliminar los espacios
      url = url.replace(/\s/g, "%20");
      // Si la ruta actual es igual a la siguiente ruta no sucede nada
      if (this.$route.path.slice(1) === url) return -1;
      this.$router.push(url);
    },
    formatTitle(title) {
      return title.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    getContadores() {
      const id_dispositivo = localStorage.getItem("dispositivos");
      axios.post(process.env.VUE_APP_API_URL + "/getContadores", {
        id_dispositivo: id_dispositivo,
        hist: 1
      })
        .then((res) => {
          this.hasContadores = res.data.length > 0;
        })
    },
  },
  beforeMount() {
    const index = this.itemsNav.findIndex(item => this.formatTitle(item.title) === this.$route.name);
    this.selectedItemMenu = index;
    this.getContadores();
  }
};
</script>

<style></style>