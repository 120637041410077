<template>
  <div>
    <!-- <ProgramacionesComponent
      :grupos="grupos"
      v-if="done"
    /> -->
    <ProgramacionesSecuencialesComponent :grupos="grupos" @reset-groups="resetGroups" :electrovalvulas="electrovalvulas" v-if="done"  @updateElectrovalvulas="loadElectrovalvulas"/>
    <!-- <ProgramacionesParametrizadas
    :grupos="grupos"
    v-if="done"
     /> -->
  </div>
</template>

<script>
// import ProgramacionesComponent from "@/components/ProgramacionesComponent.vue";
import ProgramacionesSecuencialesComponent from "@/components/ProgramacionesSecuencialesComponent.vue";
// import ProgramacionesParametrizadas from '@/components/programacionesParametrizadasComponent.vue';
import axios from "axios";
export default {
  components: { ProgramacionesSecuencialesComponent },
  data() {
    return {
      programacionesSecuenciales: [],
      grupos: [],
      done: false,
      electrovalvulas: [],
    };
  },

  beforeMount() {
    this.getGrupos();
    this.fetchElectrovalvulas();
  },

  methods: {
    resetGroups() {
    this.grupos = this.grupos.map(group => ({
      ...group,
      disabled: false
    }));
    },
    
    getGrupos() {
      // Obtengo los grupos
      axios
        .post(process.env.VUE_APP_API_URL + "/getGrupos", {
          id_dispositivo: localStorage.getItem("dispositivos"),
        })
        .then((res) => (this.grupos = res.data))
        .catch((err) => console.error(err))
        .finally(() => {
          this.done = true;
        })
    },

    fetchElectrovalvulas() {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/getElectrovalvulas",
          { id_dispositivo: localStorage.getItem("dispositivos") },
        )
        .then((res) => {
          this.electrovalvulas = res.data;
        })
        .catch((err) => console.error(err));
    },

    loadElectrovalvulas() {
        axios.post(`${process.env.VUE_APP_API_URL}/getElectrovalvulas`, {
            id_dispositivo: localStorage.getItem("dispositivos"),
        })
        .then((res) => {
            this.electrovalvulas = res.data;
        })
        .catch((err) => {
            console.error("Failed to load electrovalvulas:", err);
        });
    },
  },
};
</script>

<style></style>