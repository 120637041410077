<template>
  <v-container class="d-flex justify-center align-center" style="height:80vh">
    <v-card class="teal darken-4 py-8" width="600">
      <v-container class="d-flex flex-column align-center justify-center">
        <figure>
          <v-img
            min-width="150px"
            max-width="200px"
            src="../assets/logoWhite.png"
          ></v-img>
        </figure>
      </v-container>
      <v-form class="d-flex justify-center align-center flex-column" @submit="login">
        <v-text-field v-model="email" label="Email" dark :style="inputWidth" :rules="[error]" required>
        </v-text-field>
        <v-text-field
          color="white"
         :type="showPassword ? 'text' : 'password'"
          v-model="password"
          label="Password"
          :style="inputWidth"
          dark
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="togglePasswordVisibility"
          :rules="[error]"
          required
        >
        </v-text-field>
        <v-btn type="submit" dark class="mt-4">Iniciar Sesión</v-btn>
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  data() {
    let dispositivos = []
    return {
      email: "",
      password: "",
      showPassword: false,
      dispositivos,
      error:true
    };
  },
  methods:{
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    login(e) {
      e.preventDefault()
        axios
          .post(
            `${process.env.VUE_APP_API_URL}/login`,
            {
              email: this.email,
              password: this.password,
            }
          )
          .then((response) => {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("id", response.data.id);
            localStorage.setItem("email", response.data.email);
            localStorage.setItem("nombre", response.data.name);
            localStorage.setItem("apellido", response.data.last_name);
            localStorage.setItem("URL_avatar", response.data.URL_avatar);
            localStorage.setItem("fincas", response.data.fincas);
            localStorage.setItem("usuario",btoa(response.data.rol))
            const finca = JSON.parse(response.data.fincas)
            localStorage.setItem("dispositivos",(finca[0].dispositivos).replace(/\s/g, ''))
            localStorage.setItem("id_finca",finca[0].id)
          })
          .catch(() => {
            this.error = false
          })
          .finally(() => {
              this.$router.push("/Panel%20de%20control");
          })
      }
    },
  computed: {
    inputWidth() {
      return this.$vuetify.breakpoint.mobile ? "width:80%" : "width:70%";
    },
  },
};
</script>

<style>
</style>