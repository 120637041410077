<template>
  <div>
    <v-data-table :headers="headers" :items="data" item-key="fecha_registro" class="elevation-1" hide-default-footer
      show-select :single-select="false" :items-per-page="-1" sort-by="fecha_registro" sort-desc
      checkbox-color="teal darken-3" loading-text="Cargando..." locale="es-es" :no-data-text="tableText" v-model="selected" :loading="isLoading">
      <template v-slot:top>
        <v-toolbar flat class="mb-2">
          <!-- TITULO -->
          <v-toolbar-title>Informes</v-toolbar-title>
          <v-spacer></v-spacer>
          <template v-if="hasSelected">
            <v-btn @click="openDialogDelete" fab small dark color="red darken-3" class="mr-2">
              <v-icon>mdi-delete-sweep</v-icon>
            </v-btn>
          </template>
          <!-- Generar Informe -->
          <v-btn color="teal darken-2" @click="openDialog()" fab small dark>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <!-- Fecha registro -->
      <template v-slot:[`item.fecha_registro`]="{ item }">
        {{ item.fecha_registro.replace("T", " ").slice(0, -5) }}
      </template>
      <!-- Fecha modificación -->
      <template v-slot:[`item.fecha_modificacion_s`]="{ item }">
        {{ item.fecha_modificacion_s.replace("T", " ").slice(0, -5) }}
      </template>
      <!-- Acciones -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="downloadPDF(item)"> mdi-download </v-icon>
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <!-- DIALOGO  CREAR / EDITAR -->
    <v-dialog @click:outside="closeDialog" v-model="dialog" max-width="500px" v-if="dialog" @keydown.esc="closeDialog">
      <v-card>
        <v-card-title>
          <span class="headlines"> {{ formTitle }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="closeDialog">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="editedItem.nombre" label="Nombre de informe *" rounded outlined class="pt-1" 
                  placeholder="Ej.: Reporte 01, Reporte de sensor" :error-messages="nombreErrors" :rules="[v => !!v || 'Campo requerido', v => !nombreExists || nombreExistMessage]">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <span class="subtitle-2 ml-4 mt-n3">Selecciona las fechas para determinar el período del informe</span>
              <v-col md="6" cols="12">
                <v-menu :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="fechaInicio" label="Fecha de inicio *" prepend-icon="mdi-calendar" readonly :rules="[v => !!v || 'Fecha de inicio es requerida']"
                      class="px-2 mt-n1" v-bind="attrs" v-on="on" color="teal darken-2"></v-text-field>
                  </template>
                  <v-date-picker v-model="fechaInicio" locale="es-es" :first-day-of-week="1" color="teal darken-4"
                    min="01-01-2022" :max="fechaFin == null ? date : fechaFin"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col md="6" cols="12">
                <v-menu class="py-0" :close-on-content-click="true" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="fechaFin" label="Fecha de fin *" prepend-icon="mdi-calendar" readonly :rules="[v => !!v || 'Fecha de fin es requerida']"
                      class="px-2 mt-n1" v-bind="attrs" v-on="on" color="teal darken-2"></v-text-field>
                  </template>
                  <v-date-picker v-model="fechaFin" locale="es-es" :first-day-of-week="1" color="teal darken-4"
                    :min="fechaInicio" :max="date"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog" text color="red darken-1">Cancelar</v-btn>
          <v-btn text color="teal darken-1" @click="generarInforme" :disabled="!isFormValid">Generar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOGO  ELIMINAR -->
    <v-dialog v-model="dialogDelete" max-width="500px" @keydown.esc="close('dialogDelete')">
      <v-card>
        <v-card-title class="headline subtitle-1">Eliminar {{ editedItem.nombre }}</v-card-title>
        <v-card-text class="subtitle-2">
          ¿Estás seguro de que quieres eliminar el reporte <span class="font-weight-bold">{{ editedItem.nombre
            }}</span>?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="teal darken-1" text @click="close('dialogDelete')">Cancelar</v-btn>
          <v-btn color="red darken-1" text @click="deleteItemConfirm()">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOGO  ELIMINAR SELECCIONADOS -->
    <v-dialog v-model="dialogDeleteSelected" max-width="500px" @click:outside="closeDialogDelete" @keydown.esc="closeDialogDelete()">
      <v-card>
        <v-card-title class="headline subtitle-1">Eliminar informes seleccionados</v-card-title>
        <v-card-text>
          <v-icon color="red darken-2" class="mr-2">mdi-alert-circle</v-icon>
          <span class="font-weight-bold">¡Atención!</span>
          <br>
          <p> Vas a proceder a eliminar<span class="font-weight-bold"> todos los informes seleccionados.</span>
            Ten en cuenta que esta acción no se puede deshacer.</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="teal darken-1" text @click="closeDialogDelete()">Cancelar</v-btn>
          <v-btn color="red darken-1" text @click="deleteSelectInformes()">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      date: new Date().toISOString(),
      isLoading: false,
      tableText: "Para generar un informe pulse el botón '+'",
      data: [],
      headers: [
        {
          text: "Nombre de informe",
          value: "nombre",
          class: "primary--text",
        },
        {
          text: "Fecha de creación",
          value: "fecha_registro",
          class: "primary--text",
        },
        {
          text: "Fecha de modificación",
          value: "fecha_modificacion_s",
          class: "primary--text",
        },
        {
          text: "Acciones",
          value: "actions",
          class: "primary--text",
          sortable: false,
          width: "10%",
        },
      ],
      fechaInicio: null,
      fechaFin: null,
      dialog: false,
      dialogDelete: false,
      editedItem: {
        id: 0,
        nombre: "",
      },
      editedIndex: -1,
      dialogDeleteSelected: false,
      selected: [],
      nombreExistMessage: "Este nombre de informe ya está en uso.",
      isEditing: false,
      nombreCheck: false,
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
      this.nombreCheck = false;
    },

    openDialogDelete() {
      this.dialogDeleteSelected = true;
    },

    closeDialogDelete() {
      this.dialogDeleteSelected = false;
    },

    closeDialog() {
      this.dialog = false;
      this.isEditing = false;
      this.editedItem = {
        id: 0,
        nombre: "",
      };
      this.editedIndex = -1;
      this.fechaInicio = null;
      this.fechaFin = null;
      this.nombreCheck = false;
    },

    generarInforme() {
      this.isLoading = true;
      if (this.editedIndex > -1) {
        axios
          .post(`${process.env.VUE_APP_API_URL}/updateInformes`, {
            id_finca: localStorage.getItem("id_finca"),
            id_informe: this.editedItem.id,
            nombre_informe: this.editedItem.nombre,
            fecha_inicio: this.fechaInicio,
            fecha_fin: this.fechaFin,
          })
          .then((res) => {
            if (res && res.data) {
              this.closeDialog();
            }
          })
          .finally(() => {
            this.isLoading = false;
            this.getInformes();
          });
      } else {
        axios
          .post(`${process.env.VUE_APP_API_URL}/insertInformes`, {
            id_finca: localStorage.getItem("id_finca"),
            id_usuario: localStorage.getItem("id"),
            nombre_informe: this.editedItem.nombre,
            fecha_inicio: this.fechaInicio,
            fecha_fin: this.fechaFin,
          })
          .then((res) => {
            if (res && res.data) {
              this.closeDialog();
            }
          })
          .finally(() => {
            this.isLoading = false;
            this.getInformes();
          });
      }
    },

    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem.nombre = item.nombre;
      this.editedItem.id = item.id;
      this.fechaInicio = item.fecha_inicio.replace("T", " ").slice(0, -14);
      this.fechaFin = item.fecha_fin.replace("T", " ").slice(0, -14);
      this.dialog = true;
      this.isEditing = true;
      this.nombreCheck = true;
    },

    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    close(name) {
      this[name] = false;
    },

    getInformes() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/getInformes`, {
          id_finca: localStorage.getItem("id_finca"),
        })
        .then((res) => {
          this.data = res.data;
        });
    },

    async downloadPDF(item) {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/downloadPDF`, {
          id_informe: item.id,
        }, {
          responseType: 'blob'
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Informe_${item.nombre}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error al descargar PDF:", error);
      }
    },

    deleteSelectInformes() {
      if (this.selected.length === 0) return;

      this.isLoading = true;
      const ids = this.selected.map(item => item.id);

      axios
        .post(`${process.env.VUE_APP_API_URL}/deleteSelectInformes`, {
          ids: ids,
        })
        .then((res) => {
          if (res && res.data) {
            this.closeDialogDelete();
          }
        })
        .finally(() => {
          this.isLoading = false;
          this.selected = [];
          this.getInformes();
        });
    },

    deleteItemConfirm() {
      this.isLoading = true;
      axios
        .post(`${process.env.VUE_APP_API_URL}/deleteInformes`, {
          id_informe: this.editedItem.id,
        })
        .then((res) => {
          if (res && res.data) {
            this.close("dialogDelete");
          }
        })
        .finally(() => {
          this.isLoading = false;
          this.getInformes();
        });
    },

    checkNombre() {
      const nombreTrimmed = this.editedItem.nombre.trim().toLowerCase();
      this.nombreExistMessage = this.data.some((item, index) => item.nombre.trim().toLowerCase() === nombreTrimmed && index !== this.editedIndex)
        ? "Este nombre de informe ya está en uso."
        : "";
      this.nombreCheck = true;
    },
  },
  computed: {
    hasSelected() {
      return this.selected.length > 0;
    },
    nombreErrors() {
      return this.nombreCheck && this.nombreExistMessage ? [this.nombreExistMessage] : [];
    },
    nombreExists() {
      if (!this.nombreCheck) return false;
      const nombreTrimmed = this.editedItem.nombre.trim().toLowerCase();
      if (this.editedIndex > -1) {
        return this.data.some((item, index) => item.nombre.trim().toLowerCase() === nombreTrimmed && index !== this.editedIndex);
      }
      return this.data.some((item) => item.nombre.trim().toLowerCase() === nombreTrimmed);
    },
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo informe" : "Editar informe";
    },
    isFormValid() {
      return this.editedItem.nombre.trim() !== "" && !this.nombreExists && this.fechaInicio && this.fechaFin;
    },
  },
  watch: {
    'editedItem.nombre': 'checkNombre'
  },
  mounted() {
    this.getInformes();
  },
};
</script>

<style></style>